import {useMetaMask} from "metamask-react";
import {useState} from "react";
import {closeModal, openmodal} from "../metamask/SideBar";
import Blockies from 'react-blockies';

export default function WalletBtn(){

    const {status, account} = useMetaMask()
    const [showModal, setShowModal] = useState(false)

    const toggleModal = () => {
        if (showModal){
            closeModal();
            setShowModal(false);
        } else if (!showModal) {
            openmodal()
            setShowModal(true);
        }
    }

    if (status === 'connected'){
        return(
            <a onClick={toggleModal} className="btn text-sm rounded-full">
                <div className="flex flex-row gap-2">
                    <Blockies className="mask mask-circle" seed={account.toLowerCase()} size={9} scale={3}/>
                    <div className="flex flex-col justify-center">
                        {account.substring(0,5)}...{account.substring(account.length-4)}
                    </div>
                </div>
            </a>)

    } else {
        return(<a onClick={toggleModal} className="btn text-xs sm:text-sm rounded-full">Connectar Moneder</a>)


    }
}
