import {Suspense, useState} from 'react'
import { Canvas } from '@react-three/fiber'
import { Environment, Loader, BakeShadows, PointerLockControls } from '@react-three/drei'
import { Physics } from '@react-three/cannon';
import BaseCharacter from './BaseCharacter';

import Model from './Model'
import Floor from './Floor'
import Floor2 from './Floor2';

export default function Gallery3D() {
  const [nfts, setNfts] = useState(JSON.parse(window.localStorage.getItem('nfts')))

  return (
    <div className="h-screen">
      <Suspense fallback={null}>
        <Canvas dpr={[1, 2]} shadows camera={{fov:50}}>
          <fog attach="fog" args={['#eee', 0, 50]} />
          <ambientLight intensity={0.1} />
          <group position={[0, 1, 0]}>
            <spotLight castShadow intensity={10} angle={0.1} position={[-200, 220, -100]} shadow-mapSize={[2048, 2048]} shadow-bias={-0.000001} />
            <spotLight angle={0.1} position={[-250, 120, -200]} intensity={1} castShadow shadow-mapSize={[50, 50]} shadow-bias={-0.000001} />
            <spotLight angle={0.1} position={[250, 120, 200]} intensity={1} castShadow shadow-mapSize={[50, 50]} shadow-bias={-0.000001} />
            <Physics gravity={[0, -9.8, 0]}>
              <Model nfts={nfts} />
              <BaseCharacter controls position={[3, 3, 3]} args={[0.5]} color="yellow" />
              <Floor2 rotation={[Math.PI / -2, 0, 0]} color="white" />
            </Physics>
          </group>
          <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/canary_wharf_1k.hdr" background />
          <BakeShadows/>
          <PointerLockControls />
          {/*<FirstPersonControls movementSpeed={5} lookSpeed={0.08}/>*/}


        </Canvas>
        <div id="info"></div>
        </Suspense>
        <Loader />
      </div>
  )
}
