import NFTCard from "./NFTCard";
import {useEffect, useState} from "react";
import EmptyCategory from "./EmptyCategory";
import {update_nft_state} from "../../logic/ProfileLogic";
import {ethers} from "ethers";
import {useMetaMask} from "metamask-react";
import {contractFactory} from "../../logic/SmartContractLogic";
import {ABI_NFT_MARKETPLACE, ADDRESS_NFT_MARKETPLACE} from "../../smartcontracts/ABI";

export default function Gallery({data, type, handleData, likes}){

    const [ready, setReady] = useState(false)
    const [nft, setNFT] = useState([])

    const {account, status, ethereum} = useMetaMask();

    useEffect(() => {
        const nft2 = []
        if (data != null){
            for (const i in data){
                if (type === '1') {
                    nft2[i] = [i,data[i]['name'], data[i]['price'], data[i]['author'], data[i]['media'], data[i]['onsale'], data[i]['id'], data[i]['marketId'], data[i]['address']]
                }
                else if (type === '2') {
                    if (data[i]['onsale']){
                        nft2[i] = [i,data[i]['name'], data[i]['price'], data[i]['author'], data[i]['media'], data[i]['onsale'], data[i]['id'], data[i]['marketId'], data[i]['address']]
                    }
                }
                else if (type === '3') {
                    if (data[i]['author'].toLowerCase() === data[i]['holder'].toLowerCase()){
                        nft2[i] = [i,data[i]['name'], data[i]['price'], data[i]['author'], data[i]['media'], data[i]['onsale'], data[i]['id'], data[i]['marketId'], data[i]['address']]
                    }
                }
            }
            if (type === '4'){
                for (const i in likes){
                    nft2[i] = [i,likes[i]['name'], likes[i]['price'], likes[i]['author'], likes[i]['media'], likes[i]['onsale'], likes[i]['id'], likes[i]['marketId'], likes[i]['address']]

                }
            }
            setNFT(nft2)
            setReady(true)
        }
    },[data, type, likes])

    function handle_onsale_state(new_state, id){
        update_nft_state(id, new_state).then((r) => {
            handleData()
        })
    }

    function onSale(){
        /*
        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner(ethers.utils.getAddress(account))

        const contract_market = contractFactory(ADDRESS_NFT_MARKETPLACE, ABI_NFT_MARKETPLACE, signer)
        contract_market.createMarketSale()
         */
        window.location = "/listnft"

    }

    function offSale(market_id){
        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner(ethers.utils.getAddress(account))

        const contract_market = contractFactory(ADDRESS_NFT_MARKETPLACE, ABI_NFT_MARKETPLACE, signer)
        const rx = contract_market.deleteMarketItem(market_id)
        console.log('holi')


    }

    if (ready) {
        if (nft.length > 0){
            return(<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-x-8 my-10 lg:p-20 rounded-2xl border border-neutral-600">
                    {
                        nft.map((n) => <NFTCard key={n[0]}
                                                title={n[1]}
                                                preu={n[2]}
                                                autor={n[3]}
                                                img={n[4]}
                                                onsale={n[5]}
                                                id={n[6]}
                                                marketId={n[7]}
                                                changeType={handle_onsale_state}
                                                unlist={offSale}
                                                list={onSale}
                                                addr={n[8]}
                                                type={type}/>)
                    }
                </div>
            )
        }
    }
    return(<EmptyCategory/>)
}
