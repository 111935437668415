import cube2 from "../../img/cube2.gif";
import {Link} from "react-router-dom";
export default function GalleryAd({data}){
    return(
        <div className="flex flex-row  flex-wrap bg-base-300 rounded-none mt-10 mb-10 mx-auto justify-center
                        px-[4%] py-[5%] gap-6
                        md:gap-[4%] md:p-[3%] ">

            <img className="min-h-[30vh]" src={cube2}></img>
            {/*<video autoPlay loop muted playsInline src={cube2}></video>*/}
            <div className="min-h-[30vh] flex flex-col justify-center">
                <div className="badge badge-warning gap-3 px-3 py-5 mb-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                    </svg>
                    <div className=" text-xl">Nou</div>
                </div>
                <div className=" text-4xl mb-1">Galeria 3D Virtual (BETA)</div>
                <div className=" text-md mb-5">Aquesta funció és experimental i pot ser inestable</div>
                {data !== undefined && data.length !== 0
                    ? <Link  target="_blank" state={data} to="/galeria3D" className="btn btn-accent w-1/2 rounded-full">Entrar</Link>
                    : <div className="btn btn-accent loading w-1/2 rounded-full"></div>}
            </div>

        </div>
    )
}
