export function NotConnectedAlert(){
    return(
        <div className="alert text-xs p-2 rounded">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <span>MetaMask detectat pero no connectat. <br/> <a href="https://metamask.io/faqs/" target="_blank" rel="noreferrer" className="text-bold hover:underline">Més informació</a></span>
            </div>
        </div>)
}

export function ConnectingAlert(){
    return(
        <div className="alert shadow-md text-xs p-2 rounded">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-current flex-shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <span>Connectant amb Metmask...<br/></span>
            </div>
        </div>)
}
export function UnavailableAlert(){
    return(
        <div className="alert alert-error shadow-md text-sm sm:text-xs p-4 sm:p-2 rounded">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span className="justify-start">Metamask no detectat.<br/> <a href="https://metamask.io/download/" target="_blank" rel="noreferrer" className="text-bold hover:underline">Instal·lar...</a></span>
            </div>
        </div>)
}
