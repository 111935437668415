import React, {Fragment} from 'react';
import SideBar from "../components/metamask/SideBar";
import { ScrollRestoration } from "react-router-dom";

export default function Layout({Navbar, Content, Footer, Modal, setModal}){

    return(
        <Fragment>
            <ScrollRestoration/>
            {Modal !== undefined && Modal}
            <Navbar setModal={setModal}/>
            <SideBar children={Content} setModal={setModal}/>
            <Footer/>
        </Fragment>
    );
}
