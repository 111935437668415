import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import CardsNFT from "../components/home/CardsNFT";
import TableNFT from "../components/home/TableNFT";
import Layout from "./Layout";
import {Fragment, useEffect, useState} from 'react';
import {fetch_nfts, getNFTs} from "../logic/NFTLogic";
import Slides from "../components/home/Slides";
import GalleryAd from "../components/home/GalleryAd";
import ListModal from "../components/uploadnft/ListModal";


export default function HomePage (){
    const [nfts, setNfts] = useState([])
    const destacatsIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 my-auto">
        <path fillRule="evenodd" d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207 6.72 6.72 0 00.857-3.294z" clipRule="evenodd" />
    </svg>
    const trendIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
        <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z" clipRule="evenodd" />
    </svg>
    const [modalData, setModalData] = useState(undefined)


    useEffect(() => {
        getNFTs().then((r) => {
            setNfts(r)
            if (Date.now() - parseFloat(window.localStorage.getItem('nfts_timestamp')) > 30000) {
                fetch_nfts().then((r) => {
                    setNfts(r.data)
                })
            }
        }).catch((e) => {
            //logica default provider
        })

    },[])

    return(<Layout
        Navbar={Navbar}
        Modal={<ListModal modalData={modalData}/>}
        setModal={setModalData}
        Content={<Fragment>
            <Slides data={nfts}/>
            <CardsNFT idCard="destacats" nfts={nfts} titol={"Destacats"} icon={destacatsIcon}/>
            <GalleryAd data={nfts}/>
            <CardsNFT idCard="populars" nfts={nfts} titol={"Populars"} icon={trendIcon}/>
            <TableNFT data={nfts}/>
        </Fragment>}
        Footer={Footer}
    />)
}
