import Card from "./Card";
import {Fragment} from "react";
import {isMobileDevice} from "../metamask/Metamask";
import {ScrollingCarousel} from "@trendyol-js/react-carousel";

export default function CardsNFT({nfts, titol, icon, idCard}){

    /*
    useEffect(() => {
        //const provider = new ethers.providers.Web3Provider(window.ethereum)
        const provider = ethers.getDefaultProvider(ethers.providers.getNetwork("sepolia"))
        const contract = contractFactory(ADDRESS_NFT_MARKETPLACE, ABI_NFT_MARKETPLACE, provider)

        contract.fetchActiveItems().then((items) => {
            const array = []
            for (const i in items){
                const aux = []
                aux[0] = items[i].nftContract.toString()
                aux[1] = parseInt(items[i].tokenId.toString())
                aux[2] = ethers.utils.formatEther(items[i].price)
                aux[3] = parseInt(items[i].id.toString())
                array[i] = aux
            }
            setnfts(array)
        })
    },[])
     */
    if(nfts !== undefined) {
        if(isMobileDevice()){
            return(
                <Fragment>
                    <div className="flex flex-row gap-4 px-3 md:px-[4%] mt-10 mb-10">
                        {icon}
                        <div className="flex text-xl font-bold my-auto ">{titol}</div>
                    </div>
                    <div className="carousel">
                    {
                        nfts.map((n) => <Card key={n['id']} idMarket={n['id']} id={n['id']} price={n['price']} img={n['media']} name={n['name']} addr={n['addr']} author={n['author']} />)
                    }
                    </div>
                </Fragment>
            );
        } else {
            return(
                <Fragment>
                    <div className="flex flex-row gap-4 px-3 md:px-[4%] mt-10 mb-10">
                        {icon}
                        <div id={idCard} className="flex text-xl font-bold my-auto ">{titol}</div>
                    </div>
                    <ScrollingCarousel className="" show={1} slide={1} swiping={true}>
                        {
                            nfts.map((n) => <Card key={n['id']} idMarket={n['id']} id={n['id']} price={n['price']} img={n['media']} name={n['name']} addr={n['addr']} author={n['author']} />)
                        }
                    </ScrollingCarousel>
                </Fragment>
            );
        }
    }
}
