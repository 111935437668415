import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import Layout from "./Layout";
import ProfileData from "../components/profile/ProfileData";
import {useEffect, useState} from "react";
import ListModal from "../components/uploadnft/ListModal";

export default function Profile(){
    const [modalData, setModalData] = useState(undefined)
    return(
        <Layout
            Navbar={Navbar}
            Modal={<ListModal modalData={modalData}/>}
            setModal={setModalData}
            Footer={Footer}
            Content={
                <div className="max-w-[1400px] w-full p-4 mx-auto">
                    <ProfileData/>
                </div>}
        />
    )


}
