import Layout from "./Layout";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import MintForm from "../components/uploadnft/MintForm";
import {useState} from "react";
import ListModal from "../components/uploadnft/ListModal";

export default function MintNFT (){

    const [modalData, setModalData] = useState(undefined)

    return(
        <Layout
            Modal={<ListModal modalData={modalData}/>}
            setModal={setModalData}
            Navbar={Navbar}
            Footer={Footer}
            Content={
                <div className="max-w-[1400px] w-full p-4 mx-auto pt-10 pb-10 sm:pb-40 overflow-hidden">
                    <MintForm setModal={setModalData}/>
                </div>
            }
        />
    )
}
