import nftMinting from "../../img/nft_mint_redux.gif";
import {Fragment} from "react";

export function ModalBuyData1({img, name, price}){
    return(
        <Fragment>
            <img src={img} className="mx-auto py-2 w-50"/>
            <p className="text-center font-bold p-2">Nom: {name}</p>
            <p className="text-center font-semibold">Preu: {price} ETH</p>
            <div className="flex flex-row justify-center mt-3">
                <div className="btn btn-sm btn-primary loading" >CONSULTA EL TEU MONEDER I ACCEPTA EL PAGAMENT</div>
            </div>

        </Fragment>
    )
}

export function ModalBuyData2({img,tx}){
    return(
        <Fragment>
            <img src={img} className="mx-auto py-2 w-50"/>
            <div className="flex flex-row justify-center mt-3">
                <a href={"https://sepolia.etherscan.io/tx/"+tx} target="_blank" className="link text-sm">Veure detalls</a>
            </div>
            <div className="flex flex-row justify-center mt-5">
                <div className="btn btn-sm btn-primary loading" >PROCESSANT LA TRANSACCIÓ</div>
            </div>
        </Fragment>
    )
}
