import {get_session_token} from "../../logic/MetamaskLogic";
import Blockies from "react-blockies";

export default function LoginCard({logged, setLogged, setModal, address}){

    const login_again = () => {
        setLogged('working')
        try{
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('expiration')
        } catch (e){

        }
        //document.location.reload(true)
        get_session_token(address, window.ethereum, setModal)
        setLogged(true)

    }

    const tancar_sessio = () => {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('expiration')
        setLogged(false)
    }

    if (logged === 'working'){
        return(
            <div className={'flex flex-col py-3 justify-center'}>
                <div className="btn rounded-xl capitalize loading text-lg w-full">Iniciar Sessió</div>
                <div className="text-xs mx-auto pt-2"> Signa el missatge al teu wallet per a continuar</div>
            </div>
        )
    } else if  (logged === false){
        return(
            <div className="flex flex-col mt-3">
                <div className="card w-full bg-base-300">
                    <div className="flex flex-col p-4 gap-4">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-col justify-center">
                                <h2 className="text-lg">Ha fallat l'inici de sessió</h2>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgba(255,0,0,0.7)" className="w-10 h-10 animate-pulse">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                            </svg>
                        </div>
                        <button onClick={login_again} className="btn btn-outline w-full"> Tornar a provar</button>

                    </div>
                </div>
                <div className="text-xs mx-auto mt-2">Signa el missatge que apareix al teu moneder</div>

            </div>)

    } else if (logged === true) {
        return <a onClick={tancar_sessio} className="hover:cursor-pointer hover:underline  text-sm flex justify-center mt-5">Tancar la sessió</a>
    }
}

export function loginModal(address){
    return(
        <div className="flex flex-col w-full justify-center">
            <Blockies seed={address.toLowerCase()} size={9} scale={21} className="mx-auto mask mask-circle"/>
            <div className="text-lg font-mono text-center mt-5">{address.substring(0,5)}...{address.substring(address.length-4)}</div>
            <div className="text-2xl mx-auto pt-10 font-bold ">Iniciant Sessió</div>
            <div className="text-md mx-auto ">Signa el missatge al teu moneder per autenticar-te</div>
            <svg className="animate-spin my-5 h-10 w-10 text-info mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div className="text-xs mx-auto">No apareix res? <a href="/" className="link">Actualitza</a></div>
        </div>
    )
}
