import Metamask from "./Metamask";

function closeModal() {
    try {
        document.getElementById('my-drawer-4').click();
        resolveFixed()
    } catch {}
}
function openmodal() {
    try{
        document.getElementById('my-drawer-4').click();
        resolveFixed()
    } catch {}

}

function resolveFixed(){
    if (document.getElementById('sidebar-content').classList.contains('fixed')){
        document.getElementById('sidebar-content').classList.remove('fixed');
    } else {
        document.getElementById('sidebar-content').classList.add('fixed');
    }
}

export default function SideBar ({children, setModal}){
    return(
        <div className="drawer drawer-end h-full">
            <input id="my-drawer-4" type="checkbox" className="drawer-toggle"/>
            <div className="drawer-content">
                {/*<!-- Page content here -->*/}
                {children}
                <label htmlFor="my-drawer-4" className="drawer-button btn btn-primary hidden">Open drawer</label>
            </div>
            <div id='sidebar-content' className="drawer-side h-full">
                <label htmlFor="my-drawer-4" className="drawer-overlay"></label>
                <div className="py-5 px-4 w-screen sm:w-80 bg-base-100 text-base-content">
                    {/*<!-- Sidebar content here -->*/}
                    <BarraTitol/>
                    <div className="divider my-1"></div>
                    <Metamask setModal={setModal}/>
                </div>
            </div>
        </div>
    );
}

function BarraTitol({toggle}){
    return(
        <div className="flex flex-row justify-between">
            <div className="flex flex-row gap-3">
                <div className="flex flex-col justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M2.273 5.625A4.483 4.483 0 015.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 3H5.25a3 3 0 00-2.977 2.625zM2.273 8.625A4.483 4.483 0 015.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0018.75 6H5.25a3 3 0 00-2.977 2.625zM5.25 9a3 3 0 00-3 3v6a3 3 0 003 3h13.5a3 3 0 003-3v-6a3 3 0 00-3-3H15a.75.75 0 00-.75.75 2.25 2.25 0 01-4.5 0A.75.75 0 009 9H5.25z" />
                    </svg>
                </div>
                <div className="flex flex-col justify-center"><div className="font-sans text-xl font-semibold">El teu moneder</div></div>
            </div>
            <div className="flex flex-col justify-center cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6" onClick={()=>closeModal()}>
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                </svg>
            </div>
        </div>);
}

export {closeModal, openmodal}
