import {Link} from "react-router-dom";

export default function TableNFT({data}){
    if (data !== undefined){
        return(<div className="overflow-x-auto w-full mt-10">
            <table id="taula" className="table w-full">
                {/* head */}
                <thead>
                <tr>
                    <th>Nom de l'NFT</th>
                    <th>Preu</th>
                    <th>Autor</th>
                    <th>Propietari</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {/* row 1 */}
                {
                    data.map((nft) => <tr key={nft.id}>
                        <td>
                            <div className="flex items-center space-x-2">
                                <div className="avatar">
                                    <Link to={"/nft/"+nft.addr+"/"+nft.id} className="mask mask-squircle w-12 h-12 bg-cover" style={{backgroundImage:`url(${nft.media})`}}>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={"/nft/"+nft.addr+"/"+nft.id} className="font-bold">{nft.name}</Link>
                                    <div className="text-sm opacity-50">{nft.author}</div>
                                </div>
                            </div>
                        </td>
                        <td>
                            {nft.price} ETH
                        </td>
                        <td>{nft.author}</td>
                        <td>{nft.author}</td>
                        <th>
                            <Link to={"/nft/"+nft.addr+"/"+nft.id} className="btn btn-ghost btn-xs">Més detalls</Link>
                        </th>
                    </tr> )
                }
                </tbody>
            </table>
        </div>);
    }
}
