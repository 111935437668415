import { useMetaMask } from "metamask-react";
import metamaskpng from "../../img/MetaMask_Fox.svg.png";
import {Fragment, useEffect, useState} from 'react';
import {ethers} from "ethers";
import {checkToken, get_session_token} from "../../logic/MetamaskLogic";
import NetCard from "./NetCard"
import {UnavailableAlert, ConnectingAlert, NotConnectedAlert} from "./Alerts"
import Balance from "./Balance"
import LoginCard from "./LoginCard";

export function ShrinkAccount({account, classname, text, loading}){
    if (account != null){
        return(<div className={classname}>{account.substring(0,5)}...{account.substring(account.length-4)}</div>)
    }

    if (loading != null){
        return(<div>{loading}</div>)
    }

    if (text != null){
        return(<div className={classname}>{text}</div>)
    }

    return(<div className={classname}>0x478...6G89</div>)
}

function Template({buttonText, buttonClick, buttonclass, alert}){
    return(
        <div className="flex flex-col gap-4">
            {alert}
            <div className="text-md sm:text-sm">Connecta el teu moneder per iniciar sessió i així comprar i vendre NFTs.</div>
                <button className={`btn ${buttonclass}`} onClick={buttonClick}>
                    <div className="flex flex-row gap-4">
                        <img alt="metamask" src={metamaskpng}/>
                        <div className="flex flex-col justify-center">{buttonText}</div>
                    </div>
                </button>
            <div className="text-md sm:text-sm">Si no disposes d'un moneder, selecciona un proveïdor i <span className="font-bold" href="https://metamask.io/" target="_blank" rel="noreferrer">crea un ara</span>.</div>
        </div>);
}

export function isMobileDevice() {
    return 'ontouchstart' in window || 'onmsgesturechange' in window;
}

export default function Metamask({setModal}) {
    const { status, connect, account, chainId, ethereum, switchChain } = useMetaMask();
    const url = 'https://metamask.io/download/';
    const [balance, setBalance] = useState('0')
    const [logged, setLogged] = useState(false)

    useEffect(() => {
        if (status === "connected" && logged === false){
            if (window.localStorage.getItem("token") == null){
                setLogged('working')
                get_session_token(account, ethereum, setModal).then((r) => {
                    setLogged(true)
                }).catch((e) => {setLogged(false)})
            } else {
                setLogged(true)
            }
        }
        if (status === "notConnected"){
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('expiration')
            setLogged(false)
        }
    },[status])

    useEffect(() => {
        if (account != null && ethereum != null){
            ethereum.request({
                method:'eth_getBalance',
                params: [account, 'latest']
            }).then(balance => {
                try{
                    setBalance(ethers.utils.formatEther(balance))
                } catch {
                    setBalance('0')
                }
            }).catch(error => {
                console.log('error')
                setBalance('0')
            })
        }
    },[account,chainId])

    useEffect(() => {
        try{
            console.log("checking token...")
            checkToken(account, window.ethereum)

        } catch (e){
            console.log("error checking token")
        }
    }, [ethereum])

    if (isMobileDevice() && status ==='unavailable') return <Template buttonText={'MetaMask'} buttonClick={() => window.open("https://metamask.app.link/dapp/criptoart.cat/")}/>
    else if (status === "initializing") return <Template buttonText={''} buttonclass={'loading disabled'} alert={<ConnectingAlert/>}/>

    else if (status === "unavailable") return <Template buttonText={"Instal·lar MetaMask"} buttonClick={() => window.open(url).focus()} buttonclass={''} alert={<UnavailableAlert/>}/>

    else if (status === "notConnected") return <Template buttonText={"MetaMask"} buttonClick={connect} buttonclass={' '} alert={<NotConnectedAlert/>}/>

    else if (status === "connecting") return <Template buttonText={''} buttonclass={'loading disabled'} alert={<ConnectingAlert/>}/>

    else if (status === "connected") return (
        <Fragment>
            <div className="flex flex-col mt-5 mx-5">
                <Balance account={account} balance={balance} setModal={setModal}/>
            </div>
            <div className="divider w-full"></div>
            <NetCard id={chainId} switchChain={switchChain} setModal={setModal}/>
            <LoginCard logged={logged} setLogged={setLogged} setModal={setModal} address={account}/>
        </Fragment>)

    return null;
}

export function metamaskModal(){
    return(
        <div className="flex flex-col w-full justify-center">
            <div className="text-2xl mx-auto py-5 font-bold ">Connecta <br/> el Moneder</div>
            <div className="text-md mx-auto ">Signa el missatge al teu moneder per autenticar-te</div>
            <svg className="animate-spin my-5 h-10 w-10 text-info mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div className="text-xs mx-auto">No apareix res? <a href="/" className="link">Actualitza</a></div>
        </div>
    )
}
