import {useEffect, useState} from "react";
import WalletBtn from "./WalletBtn";
import {NavLink} from "react-router-dom";

function DarkLightMode(props){
    const moon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
    </svg>

    const sun = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="yellow" className="w-6 h-6">
        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z" />
    </svg>

    useEffect( () => {
        document.querySelector('html').setAttribute('data-theme', props.theme)
    })

    return(<a className="text-2xl" onClick={props.toggleTheme}>{props.theme === 'light' ? moon : sun}</a>)
}
export default function Navbar({setModal}){

    const [theme, setTheme] = useState(window.localStorage.getItem('theme'))
    const [done, setDone] = useState(false)
    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }

    useEffect(() => {

        if (window.localStorage.getItem('theme') === null) {
            window.localStorage.setItem('theme', 'dark')
        }else
            setTheme(window.localStorage.getItem('theme'))

        setDone(true)
    },[])

    useEffect(() => {
        if (window.localStorage.getItem('theme') !== theme && done)
            window.localStorage.setItem('theme',theme)

    }, [theme])

    return(<div className="bg-base-100 sticky top-0 z-50">
        <div className="max-w-[1400px] mx-auto navbar pt-4">
            <div className="navbar-start">
                <div className="dropdown">
                    <label tabIndex={0} className="btn btn-ghost md:hidden">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                    </label>
                    <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                        <li tabIndex={0}>
                            <a className="justify-between">
                                Seccions
                                <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"/></svg>
                            </a>
                            <ul className="dropdown shadow rounded-box bg-base-100 p-2">
                                <li><a href="/#destacats">Destacats</a></li>
                                <li><a href="/#populars">Populars</a></li>
                                <li><a href="/#taula">Taula</a></li>
                            </ul>
                        </li>
                        <li><NavLink to={"/profile"}>Perfil</NavLink></li>
                        <li><NavLink to={"/upload"}>Afegir NFT</NavLink></li>
                        <li><div className="flex sm:hidden hover:cursor-pointer"><DarkLightMode theme={theme} toggleTheme={toggleTheme}/></div></li>
                    </ul>
                </div>
                <NavLink to={"/"} className="btn btn-ghost text-start p-1 normal-case text-xl">
                    <div className="flex flex-row gap-3">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                        </svg>
                        <div className=" flex text-xl text-start align-top mb-1">CriptoArt</div>
                    </div>
                </NavLink>
        </div>
        <div className="navbar-center hidden md:flex">
            <ul className="menu menu-horizontal px-1">
                <li tabIndex={0}>
                    <a>
                        Seccions
                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/></svg>
                    </a>
                    <ul className="dropdown shadow bg-base-100 p-2">
                        <li><a href="/#destacats">Destacats</a></li>
                        <li><a href="/#populars">Populars</a></li>
                        <li><a href="/#taula">Taula</a></li>
                    </ul>
                </li>
                <li><NavLink to={"/profile"} >Perfil</NavLink></li>
                <li><NavLink to={"/upload"}>Afegir NFT</NavLink></li>
            </ul>
        </div>
        <div className="navbar-end">
            <div className="mx-5 hidden sm:flex hover:cursor-pointer"><DarkLightMode theme={theme} toggleTheme={toggleTheme}/></div>
            <WalletBtn/>

        </div>
        </div>
    </div>)
}

