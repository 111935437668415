import eth from '../../img/eth.svg'
import {useState, Fragment, useEffect} from "react";
import ElementMenu from "./ElementMenu";
import ContentLoader from 'react-content-loader'
import {useParams} from 'react-router-dom'
import axios from "axios";
import {config} from "../../logic/Constants";
import {useMetaMask} from "metamask-react";
import {ethers} from "ethers";
import {contractFactory} from "../../logic/SmartContractLogic";
import {ABI_NFT_MARKETPLACE, ADDRESS_NFT_MARKETPLACE} from "../../smartcontracts/ABI";
import {ModalBuyData1,ModalBuyData2} from "./ModalBuyData";
import etherscan from "../../img/etherscan_down.png"

const url = config.url.API_URL


export default function ProductInfo({setModalData}){

    const fake_data = {
        "address": "0xeae0daadbca1c560711769282feb60540c5c2695",
        "id": "5",
        "contractType": "ERC721",
        "title": "Bobobooo #2",
        "description": "this NFT is more than just a beautiful piece of art - it's also a reflection of the times we live in. Its bold, innovative style speaks to the rapidly evolving landscape of digital art, and its unique nature makes it a valuable addition to any collection.",
        "metadata": "https://alchemy.mypinata.cloud/ipfs/bafkreifyoaksmsaedzhy2s3umogn2eo2adojrumvsgbiiivrwpmdv2w35q",
        "media": "https://ipfs.io/ipfs/bafkreiafajpdbh5dxbo22uzttwuovqx34bjwmukfc73wmftpvv3xbljrr4",
        "lastUpdated": "2023-05-07",
        "chain": "Sepolia",
        "onOffChain": "Off Chain",
        "opensea": false,
        "openseaMetaData": '{}',
        "owner": "12345",
        "creator": "UNKNOWN",
        "likes": -1,
        "liked": false,
        "raw": "{metadata bla bla}",
        "listed": false,
        "price": "0.5"
    }
    const { contractAddress, id } = useParams();
    const [data, setData] = useState({})
    const [color, setColor] = useState("currentColor")
    const {account, status, connect, ethereum} = useMetaMask();

    useEffect(() => {
        isliked()
        axios.get(url + 'api/nft/'+ contractAddress + '/' + id).then((r) => {
            console.log(r.data)
            setData({
                "address": r.data.address,
                "id": r.data.id,
                "contractType": r.data.contractType,
                "title": r.data.title,
                "description": r.data.description,
                "metadata": r.data.metadata,
                "media": r.data.media,
                "lastUpdated": r.data.lastUpdated,
                "chain": r.data.chain,
                "onOffChain": r.data.onOffChain,
                "opensea": r.data.opensea,
                "openseaMetaData": r.data.openseaMetaData,
                "owner": r.data.owner,
                "creator": r.data.creator,
                "likes": r.data.likes,
                "liked": r.data.liked,
                "listed": r.data.listed,
                "price": r.data.price,
                "raw": r.data.raw,
                "marketId":r.data.marketId
            })
        }).catch((e) => {
            if(e.response.status === 404){
                window.location = "/" //TODO canviar aixo
            }
        })
    }, [contractAddress, id])

    async function isliked(){
        const liked = await axios.get(url + "api/like/"+contractAddress+"/"+id,{auth: {password: window.localStorage.getItem('token')}})
        if (liked.data)
            setColor("red")
        else
            setColor("currentColor")

    }

    async function handleLike() {
        await axios.post(url + "api/like/"+contractAddress+"/"+id,{},{auth: {password: window.localStorage.getItem('token')}})
        await isliked()
    }

    async function handleBuy() {

        document.getElementById('modal').className = "modal modal-open"

        const overrides = {
            value: ethers.utils.parseEther(data.price),
            gasLimit: 10000000
        }

        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner(ethers.utils.getAddress(account))

        const contract_market = contractFactory(ADDRESS_NFT_MARKETPLACE, ABI_NFT_MARKETPLACE, signer)

        try {
            setModalData(<ModalBuyData1 name={data.title} img={data.media} price={data.price} />)
            console.log(data.marketId)
            const buy = await contract_market.createMarketSale(contractAddress, data.marketId, overrides)
            console.log(buy)
            setModalData(<ModalBuyData2 img={data.media} tx={buy.hash}/> )
            const aux = await buy.wait()
            console.log('aux')

            /*
            if (buy.status !== 1){
                throw 'Error Buying 404'
            }


            setModalData(<div className="flex flex-col justify-center">
                <div className="text-center text-color-red">Item Succesfully Bought</div>
                <div className="btn btn-sm btn-primary" onClick={() => {window.location.refresh()}}>Close</div>
            </div>)

             */

            window.location.reload()

        } catch (e){
            setModalData(<div className="flex flex-col justify-center">
                <div className="text-center text-color-red">Error Comprant l'NFT</div>
                <div className="btn btn-sm btn-primary" onClick={() => {document.getElementById('modal').className = 'modal'}}>Tancar</div>
            </div> )

        }
    }

    const EventsLoader = props => (
        <ContentLoader
            width={700}
            height={300}
            viewBox="0 0 700 300"
            backgroundColor="#a5acba"
            foregroundColor="#dbdbdb"
            {...props}
        >
            <rect x="4" y="8" rx="3" ry="3" width="7" height="288" />
            <rect x="6" y="289" rx="3" ry="3" width="669" height="8" />
            <rect x="670" y="9" rx="3" ry="3" width="6" height="285" />
            <rect x="55" y="42" rx="16" ry="16" width="274" height="216" />
            <rect x="412" y="113" rx="3" ry="3" width="102" height="7" />
            <rect x="402" y="91" rx="3" ry="3" width="178" height="6" />
            <rect x="405" y="139" rx="3" ry="3" width="178" height="6" />
            <rect x="416" y="162" rx="3" ry="3" width="102" height="7" />
            <rect x="405" y="189" rx="3" ry="3" width="178" height="6" />
            <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
            <rect x="406" y="223" rx="14" ry="14" width="72" height="32" />
            <rect x="505" y="224" rx="14" ry="14" width="72" height="32" />
            <rect x="376" y="41" rx="3" ry="3" width="231" height="29" />
        </ContentLoader>
    )

    function PriceCard({price, list, owner, account, status, connect, buy}){

        //owned NFT case
        if (owner !== undefined && account !== null && owner.toLowerCase() === account.toLowerCase()){
            return(<div className="flex flex-row justify-between w-full p-3 bg-base-300 rounded-lg mt-5">
                <div className="flex flex-col w-full">
                    <div className="text-sm my-auto">
                        {price !== undefined
                            ? 'Preu actual'
                            : <div role="status" className="animate-pulse">
                                <div className="h-2.5 bg-gray-200 rounded-full ml-1 w-1/4 bg-neutral-600/50"></div>
                            </div>
                        }
                    </div>
                    <div className="text-4xl font-bold">
                        { price !== undefined
                            ? price +' ETH'
                            : <div role="status" className="animate-pulse">
                                <div className="h-8 mt-2 bg-gray-200 rounded-full w-1/3 bg-neutral-600/50"></div>
                            </div>}
                    </div>
                </div>
                {price !== undefined
                    ? <div className="py-5 px-7 rounded-lg bg-accent text-white font-semibold disabled">ADQUIRIT</div>
                    : <div role="status" className="animate-pulse">
                        <div className="h-8 py-8 px-10 my-auto bg-gray-200 rounded-lg w-1/3 bg-neutral-600/50"></div>
                    </div>}
            </div>)
        }

        //NOT LISTED CASE
        if (list !== undefined && !list){
            return(<div className="flex flex-row justify-between w-full p-3 bg-base-300 rounded-lg mt-5">
                <div className="flex flex-col w-full my-auto">
                    <div className="text-3xl font-bold my-auto">No Disponible</div>
                </div>
                <div className="min-w-fit py-4 px-6 text-lg rounded-lg bg-primary text-white font-semibold">No Disponible</div>
            </div>)
        }

        if(status != null && status !== 'connected'){
            //LOG IN Case
            return(
                <div className="flex flex-row justify-between w-full p-3 bg-base-300 rounded-lg mt-5">
                    <div className="flex flex-col w-full">
                        <div className="text-sm my-auto">
                            {price !== undefined
                                ? 'Preu Actual'
                                : <div role="status" className="animate-pulse">
                                    <div className="h-2.5 bg-gray-200 rounded-full ml-1 w-1/4 bg-neutral-600/50"></div>
                                </div>
                            }
                        </div>
                        <div className="text-4xl font-bold">
                            { price !== undefined
                                ? price +' ETH'
                                : <div role="status" className="animate-pulse">
                                    <div className="h-8 mt-2 bg-gray-200 rounded-full w-1/3 bg-neutral-600/50"></div>
                                </div>}
                        </div>
                    </div>
                    {price !== undefined
                        ? status === 'connecting' ? <div className="btn btn-lg btn-primary loading" onClick={connect}/> :<div className="btn btn-lg btn-primary" onClick={connect}>Iniciar sessió</div>
                        : <div role="status" className="animate-pulse">
                            <div className="h-8 py-8 px-10 my-auto bg-gray-200 rounded-lg w-1/3 bg-neutral-600/50"></div>
                        </div>}
                </div>
            )
        }

        //BUY CASE
        return(
            <div className="flex flex-row justify-between w-full p-3 bg-base-300 rounded-lg mt-5">
                <div className="flex flex-col w-full">
                    <div className="text-sm my-auto">
                        {price !== undefined
                            ? 'Preu Actual'
                            : <div role="status" className="animate-pulse">
                                <div className="h-2.5 bg-gray-200 rounded-full ml-1 w-1/4 bg-neutral-600/50"></div>
                            </div>
                        }
                    </div>
                    <div className="text-4xl font-bold">
                        { price !== undefined
                            ? price +' ETH'
                            : <div role="status" className="animate-pulse">
                                <div className="h-8 mt-2 bg-gray-200 rounded-full w-1/3 bg-neutral-600/50"></div>
                            </div>}
                    </div>
                </div>
                {price !== undefined
                    ? <div className="btn btn-lg btn-primary" onClick={handleBuy}>Comprar NFT</div>
                    : <div role="status" className="animate-pulse">
                        <div className="h-8 py-8 px-10 my-auto bg-gray-200 rounded-lg w-1/3 bg-neutral-600/50"></div>
                    </div>}
            </div>
        )

    }

    function NFTDetails({contractAddr, tokenId, contractType, onoffchain, chain, updated, creator}){

            return(<div className="flex flex-col">
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">Adreça del contracte</div>
                    {contractAddr !== undefined
                        ? <div className="font-mono text-clip truncate">{contractAddr}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-4/12 bg-neutral-600/50"></div>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">Token ID</div>
                    {tokenId !== undefined
                        ? <div className="font-mono">{tokenId}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-1/12 bg-neutral-600/50"></div>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">Classe de contracte</div>
                    {contractType !== undefined
                        ? <div className="font-mono">{contractType}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-2/12 bg-neutral-600/50"></div>
                    }

                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">On Chain / Off chain</div>
                    {onoffchain !== undefined
                        ? <div className="font-mono">{onoffchain}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-2/12 bg-neutral-600/50"></div>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">Xarxa</div>
                    {chain !== undefined
                        ? <div className="font-mono">{chain}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-1/12 bg-neutral-600/50"></div>
                    }
                </div>
                <div className="flex flex-row justify-between">
                    <div className="font-semibold mb-1">Creat</div>
                    {updated !== undefined
                        ? <div className="font-mono">{updated}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-3/12 bg-neutral-600/50"></div>
                    }
                </div>
                <div className="flex flex-row justify-between gap-4">
                    <div className="font-semibold mb-1">Autor</div>
                    {creator !== undefined
                        ? <div className="font-mono text-clip truncate">{creator}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full w-4/12 bg-neutral-600/50"></div>
                    }
                </div>
                {/*
            <div className="flex flex-row justify-between">
                <div className="font-semibold mb-1">Metadata</div>
                <div className="font-mono">ipfs.io/23787473/w8r7w8743</div>
            </div>
            */}
            </div>)
    }

    function NFTImage({img, chain, like, name}){
        return(
            <Fragment>
                <div className="flex flex-row w-full rounded-t-lg bg-base-300 py-2 px-3 justify-between">
                    {chain !== undefined
                        ? <img className="my-auto" src={eth}/>
                        : <div role="status" className=" my-auto animate-pulse rounded-full p-3 bg-neutral-600/50"></div>
                    }
                    {name !== undefined
                        ? <div className="text-sm font-bold font-mono my-auto">{name}</div>
                        : <div role="status" className="animate-pulse h-2.5 bg-gray-200 rounded-full ml-1 w-1/4 bg-neutral-600/50 my-auto"></div>}
                    {like !== undefined
                        ? <svg xmlns="http://www.w3.org/2000/svg" onClick={handleLike} viewBox="0 0 24 24" fill={like ? 'red' : color} className="w-6 h-6 my-auto hover:cursor-pointer">
                            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                        </svg>
                        : <div role="status" className=" my-auto animate-pulse rounded-full p-3 bg-neutral-600/50"></div>
                    }
                </div>
                {img !== undefined
                    ? <a className="flex flex-row rounded-b-lg min-h-[35rem] bg-base-300 bg-cover bg-center" href={img} target='_blank' style={{backgroundImage:`url(${img})`}}></a>
                    : <div className="flex flex-row rounded-b-lg min-h-[35rem] bg-base-300 animate-pulse">
                        <svg className="w-12 h-12 text-neutral-600 mx-auto my-auto" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" /></svg>
                    </div>
                }
            </Fragment>
    )
    }

    function NameCard({name, owner}){
        return(
            <div className="flex flex-row gap-1 w-full justify-between">
                <div className="flex flex-col">
                    <div className="text-3xl font-bold font-mono">{
                        name !== undefined
                            ? name
                            : <div role="status" className="animate-pulse h-5 py-2 px-20 my-auto bg-gray-200 rounded-lg w-1/3 bg-neutral-600/50"/>
                    }</div>
                    <div className="text-sm font-mono">{
                        owner !== undefined
                            ? 'PROPIETARI: ' + owner
                            : <div role="status" className="animate-pulse h-5 py-2 px-10 mt-1 my-auto bg-gray-200 rounded-lg w-1/3 bg-neutral-600/50"/>
                    }</div>
                </div>

            </div>
        )
    }

    function Metadata({raw}){
        return(<div className="mockup-code">
            <pre data-prefix="$"><code>{raw}</code></pre>
        </div>)
    }

    return(
        <div className="flex flex-row flex-wrap w-full gap-8 md:gap-[3%]">
            {/*IMG COL*/}
            <div className="flex flex-col rounded-lg w-full md:w-[44.5%]">
                <NFTImage like={data.liked} chain={data.chain} img={data.media} name={data.title}/>
                {/*METADATA*/}
                <div className="flex flex-row w-full order-last	hidden md:block">
                    <ElementMenu parentClassName="mt-5 w-full rounded-lg" title="Metadades NFT" text={<Metadata raw={data.raw}/>} data={data}
                                 altIcon={<a href={data.metadata} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                     <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                 </svg></a>}
                                 icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 7.5l3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0021 18V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v12a2.25 2.25 0 002.25 2.25z"/>
                    </svg>
                    }/>
                </div>
            </div>
            {/*DATA COL*/}
            <div className="flex flex-col flex-wrap w-full md:w-[52.5%]">
                {/*NAME + OWNER*/}
                {<NameCard name={data.title} owner={data.owner}/>}
                {/*Price card*/}
                {<PriceCard price={data.price} list={data.listed} owner={data.owner} account={account} status={status} connect={connect} buy={handleBuy}/>}
                {/*icons row*/}
                {/*<div className="flex flex-row w-full p-1 mt-3 gap-3">
                    <div className="bg-base-300 rounded-full p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" className="w-5 h-5">
                            <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 007.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 004.902-5.652l-1.3-1.299a1.875 1.875 0 00-1.325-.549H5.223z" />
                            <path fillRule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 009.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 002.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3zm3-6a.75.75 0 01.75-.75h3a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 01-.75-.75v-3zm8.25-.75a.75.75 0 00-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-5.25a.75.75 0 00-.75-.75h-3z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>*/}
                {/*Description CARD*/}
                <ElementMenu title={"Descripció"} parentClassName="rounded-lg mt-3" text={data.description} altIcon={''} data={data}
                             icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 my-auto">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                             }/>
                {/*NFT DETAILS CARD*/}
                <ElementMenu title={"Detalls de l'NFT"} parentClassName="mt-3 rounded-lg" text={<NFTDetails chain={data.chain} contractAddr={contractAddress} contractType={data.contractType} tokenId={data.id} creator={data.creator} onoffchain={data.onOffChain} updated={data.lastUpdated}/>}
                             icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                 <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                             </svg>} data={data}
                             altIcon={<a href={"https://sepolia.etherscan.io/nft/"+data.address+"/"+data.id} target="_blank"><img className="w-[25px]" src={etherscan}/></a>}
                />
            </div>
        </div>
    )

}
