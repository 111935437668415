import {useMetaMask} from "metamask-react";
import WalletInfo from "./WalletInfo";
import {useState} from "react";
import {config} from "../../logic/Constants";
import axios from "axios";
import {mintModal} from "./ListModal";
const url = config.url.API_URL
export default function MintForm({setModal}){
    const { status, connect, account, chainId, ethereum, switchChain } = useMetaMask();
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const [msgErr, setMsgErr] = useState('')

    function renderImg(e){
        const [file] = e.target.files
        if (file) {
            document.getElementById('foto').style.backgroundImage = `url(${URL.createObjectURL(file)})`
        }
    }

    async function onsubmitForm(e) {
        e.preventDefault()

        const formData = new FormData();
        formData.append('name', name)
        formData.append('desc', desc)
        formData.append('img', document.getElementById('img').files[0])
        formData.append('account', account)

        try {
            document.getElementById('modal').className = 'modal modal-open'
            setModal(mintModal)
            const r = await axios.post(url+'api/mint',
                                        formData,
                                  {"Content-Type": "multipart/form-data"}
                )
        } catch (e) {
            document.getElementById('modal').className = 'modal'
            setMsgErr("Error fent minting de l'NFT" + (e.reason === undefined ? '.' : ': ' + e.reason))
            throw 'Error Minting NFT: '+ e

        }
        document.getElementById('modal').className = 'modal'
        window.location = "/profile"
        /*
        const request = new XMLHttpRequest();
        request.open("POST", url+'api/mint');
        request.send(formData);
         */

    }

    return(
        <div className="flex flex-row flex-wrap w-full">
            <div className="flex flex-col w-full">
                <div className="font-mono font-bold text-4xl text-center sm:text-start">
                    Crea el teu NFT (Mint)
                </div>
                <div className="font-mono text-base text-center sm:text-start px-4 sm:px-0">
                    <br/>
                    Crea Aquí el teu NFT gratis.
                    <br/>
                    Pots afegir-lo a la botiga utilitzant el formulari de Listing
                </div>
                <div className="pt-5 form-control w-full">
                    <div className="flex flex-row flex-wrap w-full gap-5 justify-between">
                        {/*columna esquerra (form)*/}
                        <div className="flex flex-col w-full lg:w-1/3 md:w-1/2 flex-wrap gap-5">
                            {/*wallet info*/}
                            <WalletInfo account={account} chainId={chainId} status={status}/>
                            {/*Row form*/}
                            <form onSubmit={onsubmitForm} className="flex flex-row flex-wrap w-full gap-4">
                                {/*Input NFT Name*/}
                                <div className="flex flex-col w-full">
                                    <label className="label">
                                        <span className="label-text">Nom NFT</span>
                                    </label>
                                    <input onChange={(e) => setName(e.target.value)} required={true} id="NFTCA" type="text" className={"input input-bordered w-full border-gray-600 "} />
                                </div>
                                {/*Input NFT description*/}
                                <div className="flex flex-col w-full">
                                    <label className="label">
                                        <span className="label-text">Decripció NFT</span>
                                    </label>
                                    <input onChange={(e) => setDesc(e.target.value)} required={true} id="CID" type="text" className={"input input-bordered w-full border-gray-600 "}/>
                                </div>
                                {/*Upload NFT*/}
                                <div className="flex flex-col w-full">
                                    <label className="label">
                                        <span className="label-text">Penja Imatge NFT</span>
                                        <span className="label-text-alt">(JPG, PNG, SVG)</span>
                                    </label>
                                    <input id="img" onChange={(e)=>{renderImg(e)}} required={true} type="file" className="file-input file-input-bordered w-full border-gray-600" />
                                </div>
                                {/*Mint button*/}
                                <div className="flex flex-col w-full">
                                    <div className="text-sm text-red-500 mt-1">{msgErr}</div>
                                    <input type="submit" value="Mint NFT" id="mint" className="btn btn-accent w-full rounded-lg mt-4"/>
                                </div>
                            </form>
                        </div>
                        <div className="flex flex-col flex-wrap max-w-fit">
                            <label className="label">
                                <span className="label-text">Previsualització</span>
                            </label>
                            <div id="foto" className="bg-cover bg-center py-40 w-80 border rounded-2xl border-gray-600" ></div>
                            <div className="pt-5 font-mono w-80 ">Nom: {name}</div>
                            <div className="pt-2 font-mono w-80 text-ellipsis">Descripció: {desc}</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
