import {Link} from "react-router-dom";
import Blockies from "react-blockies";

export default function Card({price, id, idMarket, img, name, addr, author}){

    /*
    useEffect(()=>{
        if(addr !== undefined && id !== undefined){
            const provider = ethers.getDefaultProvider(ethers.providers.getNetwork("sepolia"))
            const contract = contractFactory(addr, ABI_NFT_MINTING, provider)

            contract.tokenURI(id).then((url) => {
                const url_final = checkURL(url)

                axios.get(url_final).then((data)=>{
                    setName(data.data.name)
                    setImg(checkURL(data.data.image))
                })
            })
        }
    },[id,addr,price])
    */

    return(
        <div className="carousel-item">
            <Link to={"/nft/"+addr+"/"+id} className="flex flex-col hover:cursor-pointer">
                <img className="w-80 h-80 bg-base-content/10" src={img} alt="nft img" />
                <div className="py-4 px-2 font-bold font-mono text-lg text-clip truncate ">{name}</div>
                <div className="px-2 flex flex-row gap-3">
                    <Blockies className="mask mask-circle my-auto" seed={author !== undefined && author.toLowerCase()} size={7}/>
                    <div className="text-xs font-semibold my-auto">{author !== undefined && author.substring(0,5)}
                                                        ...
                                                        {author !== undefined && author.substring(author.length-4)}</div>
                </div>
                <div className="divider my-2 px-2"></div>
                <div className="flex flex-row justify-between gap-5 pb-3 px-3">
                    <div className="text-lg font-bold">{price} ETH</div>
                </div>
            </Link>
        </div>
    )
}
