import Blockies from 'react-blockies';
import {Link} from "react-router-dom";
export default function Featured ({data}){

        return(
            <div className="flex justify-between flex-wrap bg-base-300 rounded-none md:rounded-3xl mt-5 mb-6 mx-auto max-w-[1400px]
                        flex-col-reverse px-[4%] py-[5%] gap-6
                        md:flex-row  md:gap-[4%] md:p-[3%] ">

                {/*Columna esquerra: Data*/}
                <div className="flex flex-col w-full md:w-[48%] md:min-h-[55vh]">
                    <div className="flex flex-row gap-8">
                        <div className="animate-pulse bg-info/80 text-black rounded-full py-1 px-3 font-bold font-mono">Disponible</div>
                        <div className="font-bold font-mono my-auto">#{data.id}</div>
                    </div>
                    <div className="flex flex-row basis-10/12">
                        <Link to={"/nft/"+data.addr+"/"+data.id} className="py-10 font-black text-5xl lg:text-7xl font-mono my-auto text-clip overflow-hidden">{data.name}</Link>
                    </div>
                    {/*creador row*/}
                    <div className="flex flex-row gap-4">
                        <div className="flex flex-col my-auto">
                            <Blockies className="mask mask-circle" seed={data.author !== undefined && data.author.toLowerCase()} size={10}/>
                        </div>
                        <div className="flex flex-col">
                            <div className="text-xs">creat per</div>
                            <div className="text-md font-bold">{data.author !== undefined && data.author.substring(0,5)}
                                                                ...
                                                                {data.author !== undefined && data.author.substring(data.addr.length-4)}</div>
                        </div>
                    </div>
                    {/*price + button row*/}
                    <div className="flex flex-row pt-10 gap-10">
                        <div className="flex flex-row gap-2 items-end my-auto">
                            <div className="text-4xl font-extrabold duration-500">{data.price}</div>
                            <div className="text-sm">ETH</div>
                        </div>
                        <Link className="btn btn-sm btn-info font-bold my-auto" to={"/nft/"+data.addr+"/"+data.id}>Veure detalls</Link>

                    </div>
                </div>
                {/*Columna dreta: Imatge*/}
                <Link to={"/nft/"+data.addr+"/"+data.id} className="flex flex-col rounded-3xl w-full md:w-[48%] bg-center bg-cover min-h-[55vh] duration-500" style={{backgroundImage:`url(${data.media})`}}>
                </Link>
            </div>
        );
}
