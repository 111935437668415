import Layout from "./Layout";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import nft from "../img/nft.svg"
import shop from "../img/shop.png"
import { Link } from "react-router-dom";
import ListModal from "../components/uploadnft/ListModal";
import {useState} from "react";

export default function UploadNFT () {
    const [modalData, setModalData] = useState(undefined)
    return(
        <Layout
            Navbar={Navbar}
            Modal={<ListModal modalData={modalData}/>}
            setModal={setModalData}
            Footer={Footer}
            Content={
                    <div className="max-w-[1400px] p-4 mx-auto pt-10 sm:pt-20 pb-10 sm:pb-40">
                        <div className="flex flex-row flex-wrap w-full">
                            <div className="flex flex-col w-full">
                                <div className="font-mono font-bold text-5xl text-center sm:text-start">
                                    Afegeix un NFT
                                </div>
                                <div className="font-mono text-lg text-center sm:text-start px-4 sm:px-0">
                                    <br/>
                                    Pots afegir al marketplace un dels teus NFTs per a vendre'l.
                                    <br/>
                                    També pots utilitzar la nostra web per crear un nou NFT.
                                </div>
                            </div>
                            <div className="flex flex-row flex-wrap md:gap-10 sm:gap-5 justify-center">

                                <div className="flex flex-col flex-wrap pt-10 w-[300px]">
                                    <Link to="/mintnft">
                                        <div className="border rounded-lg py-10 px-20 hover:cursor-pointer hover:bg-blue-100 transition ease-in-out duration-500">
                                            <img className="mx-auto" src={nft} width="120"/>
                                            <div className="text-xl font-bold text-center pt-8">Mint NFT</div>
                                            <div className="text-sm text-center">Crea el teu NFT</div>
                                        </div>
                                    </Link>
                                </div>

                                <div className="flex flex-col flex-wrap pt-10 w-[300px]">
                                    <Link to="/listnft">
                                        <div className="border rounded-lg py-10 px-20 hover:cursor-pointer hover:bg-blue-100 transition ease-in-out duration-500">
                                            <img className="mx-auto" src={shop} width="120"/>
                                            <div className="text-xl font-bold text-center pt-8">List NFT</div>
                                            <div className="text-sm text-center">Ven aquí el teu NFT!</div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
            }/>
    )

}
