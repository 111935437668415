import Layout from "./Layout";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import ProductInfo from "../components/productinfo/ProductInfo";
import ListModal from "../components/uploadnft/ListModal";
import {useState} from "react";

export default function NFTInfo(){
    const [modalData, setModalData] = useState(undefined)
    return(
        <Layout
        Modal={<ListModal modalData={modalData}/>}
        setModal={setModalData}
        Navbar={Navbar}
        Footer={Footer}
        Content={
            <div className="max-w-[1400px] w-full p-4 mx-auto pt-10 pb-10 sm:pb-40 overflow-hidden">
                <ProductInfo setModalData={setModalData}/>
            </div>
        }
        />
    )
}
