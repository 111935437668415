import { ethers } from "ethers";
import axios from "axios"
import {config} from "./Constants";
import {def_chain} from "./Constants";
import {loginModal} from "../components/metamask/LoginCard";

const url = config.url.API_URL;

const chainID = {
    "0x5":"goerli testnet", //5
    "0xaa36a7":"sepolia testnet", //11155111
    "0x1":"Main ETH"

}

export function checkToken(address, ethereum, setModal){
    if (window.localStorage.getItem('token') != null){

        const exp_time = parseInt(window.localStorage.getItem('expiration'))
        const current_time = Date.now().toString().slice(0, exp_time.toString().length)

        if (exp_time - current_time <= 0){
            console.log('Token expired... requesting new one...')
            get_session_token(address, ethereum, setModal)
        }
    }
}

export function isSepolia(id){
    return id === def_chain
}

export async function get_session_token(address, ethereum, setModal){

    try{
        setModal(loginModal(address))
        document.getElementById('modal').className = "modal modal-open"

        const msg = await Login(address)
        const msg_signed = await sign(msg, ethereum)
        const token = await sendSignedMessage(msg_signed, address)

        document.getElementById('modal').className = "modal"

        return token.data

    } catch (e) {
        document.getElementById('modal').className = "modal"
        throw 'error getting token ' + e.toString()
    }

}

export async function Login(address){
    if (address != null){
        try{
            const msg = await axios.post(url + 'api/login', {'address':address})
            console.log("msg data: ", msg.data['nonce'])
            return 'Sign this message to login: \n\n'+ msg.data['nonce']
        } catch (e) {
            console.log("Error getting nonce", e)
            throw e.toString()
        }
    }
}

export async function sign(msg, ethereum){

    const provider = new ethers.providers.Web3Provider(ethereum)
    const signer = provider.getSigner()

    try{
        return await signer.signMessage(msg)
    } catch (e) {
        console.log("Error signing message", e)
        //return Promise.reject(new Error("fail"))
        throw e.toString()
    }
}

export async function sendSignedMessage(message, address){

    try{
        const res = await axios.post(url + 'api/auth',{},{auth: {username:address, password:message}})
        localStorage.setItem('token',res.data['token'])
        localStorage.setItem('expiration',res.data['exp'])
        return res.data

    } catch (e) {
        console.log('Error getting token', e)
        throw e.toString()

    }
}

export function getChainName(id){

    try{
        if (chainID[id] == null){
            return id
        } else {
            return chainID[id]
        }
    }
    catch{
        return "Unknown"
    }
}

export async function checkMetamask(status, ethereum, connect){

    if (status !== 'connected'){
        await connect()
    }
}
