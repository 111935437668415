
import React, {useEffect, useRef} from "react";
import { useGLTF, Text } from "@react-three/drei";
import { Canvas, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import Frame from "./Frame";


export default function Model({nfts}) {
  const { nodes, materials } = useGLTF("https://bafybeigyhxo6dtbxg5si6ivzy4gfkclggjhdnk4zn5az3hn3hazl7dax3e.ipfs.w3s.link/ipfs/bafybeigyhxo6dtbxg5si6ivzy4gfkclggjhdnk4zn5az3hn3hazl7dax3e/NFTSHOW_adjust.glb");
  const colorMap = useLoader(TextureLoader, 'box_boboboo.gif')
  const colorMap2 = useLoader(TextureLoader, 'yeti.png')
  const colorMap3 = useLoader(TextureLoader,"https://bafybeie5venjwpgkqpthezwtyrm7bqm5wzkiukcvqdyftd4behdamkw3re.ipfs.dweb.link/")
  const colorMap4 = useLoader(TextureLoader, nfts[0].media)
  const colorMap5 = useLoader(TextureLoader,nfts[1].media)
  const colorMap6 = useLoader(TextureLoader,nfts[2].media)
  const colorMap7 = useLoader(TextureLoader,nfts[3].media)
  const colorMap8 = useLoader(TextureLoader,nfts[4].media)

    useEffect(() => {
        console.log(nfts[0].media)
    },[])

  return (
    <group dispose={null}>
      <group
        position={[3.91, 4.51, -17.93]}
        rotation={[0, 0, 0.26]}
        scale={0.01}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh123.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh123_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group position={[3.96, 4.5, -10]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh121.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh121_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group
        position={[3.93, 4.5, -14.15]}
        rotation={[0, 0, 0.26]}
        scale={0.01}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh122.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh122_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group position={[3.98, 4.5, -6.09]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh120.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh120_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group position={[4.01, 4.49, -1.9]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh119_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group position={[4.09, 4.48, 14.3]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh116.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh116_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Conditioner_support_002.geometry}
        material={materials.Grey_Metallic}
        position={[4.11, 3.7, -6.08]}
        scale={0.01}
      />
      <group position={[4.02, 4.49, 1.95]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh118.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh118_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <group
        position={[4.05, 4.47, 17.51]}
        rotation={[0, 0, 0.26]}
        scale={0.01}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh115.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh115_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Conditioner_support_004.geometry}
        material={materials.Grey_Metallic}
        position={[4.11, 3.7, 10.1]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_002001.geometry}
        material={materials.Grey_Metallic}
        position={[-4.1, 1.1, -20.26]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_001.geometry}
        material={materials.MetalPlatesShader}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_002.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -4.04]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_003.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -8.08]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_004.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -12.12]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_005.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -16.16]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_006.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -20.2]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_007.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -24.24]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_008.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -28.28]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_009.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -32.32]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_010.geometry}
        material={materials.MetalPlatesShader}
        position={[0, 0, -36.36]}
        scale={0.01}
      />
      <group position={[4.06, 4.48, 9.94]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh117.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh117_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Conditioner_support_001.geometry}
        material={materials.Grey_Metallic}
        position={[4.11, 3.7, -14.1]}
        scale={0.01}
      />
      <group position={[4.05, 4.49, 6.19]} rotation={[0, 0, 0.26]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh178.geometry}
          material={materials.Grey}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh178_1.geometry}
          material={materials.Grey_Metallic}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Conditioner_support_003.geometry}
        material={materials.Grey_Metallic}
        position={[4.11, 3.7, 2.01]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Conditioner_support_005.geometry}
        material={materials.Grey_Metallic}
        position={[4.11, 3.7, 18.08]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_003.geometry}
        material={nodes.Roof_window_003.material}
        position={[0, 0, -8.08]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Door_002002.geometry}
        material={materials.Grey_Metallic}
        position={[-4.1, 1.1, 20.26]}
        rotation={[Math.PI, 0, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_002.geometry}
        material={nodes.Roof_window_002.material}
        position={[0, 0, -4.04]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_006.geometry}
        material={nodes.Roof_window_006.material}
        position={[0, 0, -20.2]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_001.geometry}
        material={nodes.Roof_window_001.material}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_005.geometry}
        material={nodes.Roof_window_005.material}
        position={[0, 0, -16.16]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_009.geometry}
        material={nodes.Roof_window_009.material}
        position={[0, 0, -32.32]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_004.geometry}
        material={nodes.Roof_window_004.material}
        position={[0, 0, -12.12]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_008.geometry}
        material={nodes.Roof_window_008.material}
        position={[0, 0, -28.28]}
        scale={0.01}
      />

      <Frame
            texture={colorMap4} material={materials.Painting_Back}
             title={nfts[0].name} creator={nfts[0].author.substring(0,5) +'...'+nfts[0].author.substring(nfts[0].author.length-4)}
             geometry1={nodes["pexels-steve-johnson-6377922026"].geometry} geometry2={nodes["pexels-steve-johnson-6377922026_1"].geometry}
             position={[-8, 2.6, -18.18]}
        />

      <Frame
            texture={colorMap} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922001"].geometry} geometry2={nodes["pexels-steve-johnson-6377922001_1"].geometry}
             position={[-8, 2.6, -14.14]}
        />

      <Frame
            texture={colorMap2} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922002"].geometry} geometry2={nodes["pexels-steve-johnson-6377922002_1"].geometry}
             position={[-8, 2.6, -10.1]}
        />

      <Frame
            texture={colorMap3} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922003"].geometry} geometry2={nodes["pexels-steve-johnson-6377922003_1"].geometry}
             position={[-8, 2.6, -6.06]}
        />

      <Frame
            texture={colorMap5} material={materials.Painting_Back}
             title={nfts[1].name} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922004"].geometry} geometry2={nodes["pexels-steve-johnson-6377922004_1"].geometry}
             position={[-8, 2.6, -2.02]}
        />

      <Frame
            texture={colorMap6} material={materials.Painting_Back}
             title={nfts[2].name} creator={nfts[2].author.substring(0,5) +'...'+nfts[2].author.substring(nfts[2].author.length-4)}
             geometry1={nodes["pexels-steve-johnson-6377922005"].geometry} geometry2={nodes["pexels-steve-johnson-6377922005_1"].geometry}
             position={[-8, 2.6, 2.02]}
        />

      <Frame
            texture={colorMap7} material={materials.Painting_Back}
             title={nfts[3].name} creator={nfts[3].author.substring(0,5) +'...'+nfts[3].author.substring(nfts[3].author.length-4)}
             geometry1={nodes["pexels-steve-johnson-6377922006"].geometry} geometry2={nodes["pexels-steve-johnson-6377922006_1"].geometry}
             position={[-8, 2.6, 6.06]}
        />

      <Frame
            texture={colorMap8} material={materials.Painting_Back}
             title={nfts[4].name} creator={nfts[4].author.substring(0,5) +'...'+nfts[4].author.substring(nfts[4].author.length-4)}
             geometry1={nodes["pexels-steve-johnson-6377922007"].geometry} geometry2={nodes["pexels-steve-johnson-6377922007_1"].geometry}
             position={[-8, 2.6, 10.1]}
        />

      <Frame
            material2={materials["Painting.002"]} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922008"].geometry} geometry2={nodes["pexels-steve-johnson-6377922008_1"].geometry}
             position={[-8, 2.6, 14.14]}
        />

      <Frame
            material2={materials["Painting.001"]} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922009"].geometry} geometry2={nodes["pexels-steve-johnson-6377922009_1"].geometry}
             position={[-8, 2.6, 18.18]}
        />

      <group position={[6, 1.1, 12.31]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922010"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922010_1"].geometry}
          material={materials["Painting.004"]}
        />
      </group>

      <Frame
            material2={materials["Painting.004"]} material={materials.Painting_Back}
             title={"YETI NFT"} creator={"0x123...456"}
             geometry1={nodes["pexels-steve-johnson-6377922010"].geometry} geometry2={nodes["pexels-steve-johnson-6377922010_1"].geometry}
             position={[6, 2.6, 12.31]} rotation={[0.00000000000001, Math.PI/2, Math.PI]}
        />

      <group position={[6, 1.1, 12.17]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922011"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922011_1"].geometry}
          material={materials["Painting.006"]}
        />
      </group>
      <group position={[6, 1.1, 4.23]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922012"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922012_1"].geometry}
          material={materials["Painting.007"]}
        />
      </group>
      <group position={[6, 1.1, 4.08]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922013"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922013_1"].geometry}
          material={materials["Painting.005"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_007.geometry}
        material={nodes.Roof_window_007.material}
        position={[0, 0, -24.24]}
        scale={0.01}
      />
      <group position={[6, 1.1, -3.85]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922014"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922014_1"].geometry}
          material={materials["Painting.001"]}
        />
      </group>
      <group position={[6, 1.1, -4]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922015"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922015_1"].geometry}
          material={materials["Painting.004"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder001.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 18.52]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, 0.01, -0.01]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh242.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh242_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <group position={[6, 1.1, -11.93]} rotation={[0, 1.57, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922016"].geometry}
          material={materials.Painting_Back}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922016_1"].geometry}
          material={materials["Painting.003"]}
        />
      </group>
      <group position={[6, 1.1, -12.08]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922017"].geometry}
          material={materials.Painting_Back}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922017_1"].geometry}
          material={materials["Painting.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder003.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 17.69]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0, -0.02, 0.02]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh409.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh409_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 19.36]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.07, 0.33, -0.42]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh243.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh243_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder006.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 12.89]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.01, 0.18, -0.13]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh415.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh415_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder002.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 16.7]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh407.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh407_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Roof_window_010.geometry}
        material={nodes.Roof_window_010.material}
        position={[0, 0, -36.36]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder009.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 10.44]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.08, 0.45, -0.35]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh419.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh419_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder005.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 14.63]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, 0.1, -0.07]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh411.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh411_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder004.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 15.28]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0, -0.04, 0.05]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh412.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh412_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder012.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 7.26]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.01, 0.15, -0.18]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh428.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh428_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder008.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 11.24]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.03, 0.21, -0.26]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh420.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh420_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder007.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 13.71]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.05, -0.28, 0.35]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh417.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh417_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder015.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 5.49]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.03, 0.22, -0.27]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh433.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh433_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder011.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 9.65]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.05, -0.28, 0.35]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh425.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh425_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder010.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 8.7]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, -0.02, 0.01]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh423.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh423_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder018.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 0.73]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, -0.03, 0.02]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh439.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh439_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder014.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 4.72]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.02, -0.21, 0.15]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh431.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh431_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder013.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 6.5]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh427.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh427_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder021.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -1.58]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.12, 0.53, -0.43]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh443.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh443_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder017.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 2.41]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.01, 0.18, -0.13]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh435.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh435_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder016.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 3.35]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.01, -0.1, 0.12]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh436.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh436_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder024.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -4.65]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.05, 0.28, -0.35]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh452.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh452_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder020.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -0.85]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.02, 0.17, -0.21]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh444.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh444_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder019.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, 1.7]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.02, 0.19, -0.23]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh441.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh441_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder027.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -6.36]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.05, -0.27, 0.34]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh457.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh457_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder023.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -2.48]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.1, -0.39, 0.51]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh449.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh449_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder022.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -3.21]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.05, -0.35, 0.26]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh447.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh447_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder030.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -11.43]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.04, -0.33, 0.24]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh463.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh463_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder025.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -5.45]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, -0.07, 0.05]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh451.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh451_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder033.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -13.73]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.02, -0.23, 0.16]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh467.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh467_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder026.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -7.29]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.01, -0.19, 0.14]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh455.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh455_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder028.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -8.8]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.02, -0.16, 0.19]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh460.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh460_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder036.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -16.73]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.02, 0.19, -0.23]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh476.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh476_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder029.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -9.7]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.02, 0.26, -0.19]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh459.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh459_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder031.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -10.57]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.03, 0.22, -0.27]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh465.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh465_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder039.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -18.45]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0, 0.09, -0.11]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh481.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh481_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder034.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -15.43]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.05, -0.35, 0.26]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh471.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh471_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder032.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -12.75]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.02, -0.19, 0.24]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh468.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh468_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder037.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -17.61]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0, 0.11, -0.08]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh475.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh475_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder035.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -14.57]}
        rotation={[-Math.PI / 2, 0.7, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 10.55, -12.1]} rotation={[0.04, 0.24, -0.3]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh473.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh473_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Small_Holder038.geometry}
        material={materials.Grey_Metallic}
        position={[-6.9, 2.69, -19.28]}
        rotation={[-Math.PI / 2, 0.96, -Math.PI / 2]}
        scale={-0.01}
      >
        <group position={[0, 13.43, -8.95]} rotation={[0.04, -0.31, 0.23]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh479.geometry}
            material={materials.Grey_Metallic}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Mesh479_1.geometry}
            material={materials.Emission_Small_Lamps}
          />
        </group>
      </mesh>
      <group position={[-4.1, 6.01, 16.15]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh378.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh378_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, 4.03]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh381.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh381_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, 12.11]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh379.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh379_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, 8.07]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh380.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh380_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, -8.09]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh384.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh384_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, -0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh382.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh382_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, -4.05]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh383.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh383_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, -12.13]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh385.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh385_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, -16.17]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh388.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh388_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[-4.1, 6.01, -16.17]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh386.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh386_1.geometry}
          material={materials.Emission_Long_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, -16.17]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh389.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh389_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, -12.13]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh391.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh391_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, -12.13]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh390.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh390_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, -8.09]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh392.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh392_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, -4.05]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh394.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh394_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, -8.09]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh393.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh393_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, -4.05]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh395.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh395_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, -0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh397.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh397_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, -0.01]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh396.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh396_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, 4.03]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh398.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh398_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, 8.07]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh400.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh400_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, 4.03]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh399.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh399_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, 8.07]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh401.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh401_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[2.2, 4.96, 12.11]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh403.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh403_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, 12.11]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh402.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh402_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <group position={[4.98, 3.73, 16.15]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh404.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh404_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Stand_Support001.geometry}
        material={materials.Grey_Metallic}
        position={[5.7, 3.07, -12]}
      />
      <group position={[2.2, 4.96, 16.15]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh177.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh177_1.geometry}
          material={materials.Emission_Round_Lamps}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Stand_Support002.geometry}
        material={materials.Grey_Metallic}
        position={[5.7, 3.07, -3.92]}
      />
      <group position={[4.54, 2.98, -12.66]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh333.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh333_1.geometry}
          material={materials.Emission_Stand_Lamps}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Stand_Support003.geometry}
        material={materials.Grey_Metallic}
        position={[5.7, 3.07, 4.16]}
      />
      <group position={[4.54, 2.98, 3.51]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh089.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh089_1.geometry}
          material={materials.Emission_Stand_Lamps}
        />
      </group>
      <group position={[4.54, 2.98, -4.58]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh083.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh083_1.geometry}
          material={materials.Emission_Stand_Lamps}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Lamp_Stand_Support.geometry}
        material={materials.Grey_Metallic}
        position={[5.7, 3.07, 12.24]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor.geometry}
        material={materials.Floor}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall_001001_Baked.geometry}
        material={materials["Wall_001.001_Bake1_baked"]}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wall_001.geometry}
        material={materials.Walls}
        scale={0.01}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stand_004_Baked002.geometry}
        material={materials["Stand_004_Bake1_baked.002"]}
        position={[6, 0, 4.16]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stand_002_Baked002.geometry}
        material={materials["Stand_002_Bake1_baked.002"]}
        position={[6, 0, 12.24]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stand_008_Baked002.geometry}
        material={materials["Stand_008_Bake1_baked.002"]}
        position={[6, 0, -12]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Stand_006_Baked002.geometry}
        material={materials["Stand_006_Bake1_baked.002"]}
        position={[6, 0, -3.92]}
      />
      <group position={[4.54, 2.98, 11.58]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh095.geometry}
          material={materials.Grey_Metallic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh095_1.geometry}
          material={materials.Emission_Stand_Lamps}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Column_003.geometry}
        material={materials.Metal_White}
        position={[-0.16, 0, 12.11]}
      />
    </group>
  );
}

useGLTF.preload("/NFTSHOW_adjust.glb")
