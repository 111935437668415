import {Fragment, useState} from "react";
import axios from "axios";
import {config} from "../../logic/Constants";
import nft from "../../img/nft.gif";
import {ethers} from "ethers";
import { useNavigate } from 'react-router'
const url = config.url.API_URL;
export default function Balance({account, balance, setModal}){

    const [btnTxt, setBtnTxt] = useState('Obtenir ETH')
    const [btnCls, setBtnCls] = useState('btn btn-outline w-full mt-5')
    const [dataTip, setDataTip] = useState('Copiar Adreça')
    const navigate = useNavigate()

    function closeModal(){
        document.getElementById('modal').className = "modal"
        navigate(0)
    }

    const handleFaucet = (e) => {
        e.target.disabled = true
        setBtnCls('btn btn-outline w-full mt-5 loading')
        setBtnTxt('Processing transaction')
        setModal(balanceModal1)
        document.getElementById('modal').className = "modal modal-open"

        axios.get(url+'api/faucet',{ params: { address: account }, auth: {password: window.localStorage.getItem('token')} }).then((r) => {
            console.log(r.data.transaction_id)
            setModal(balanceModal2(r.data.transaction_id))

            const provider = new ethers.providers.Web3Provider(window.ethereum)
            provider.waitForTransaction(r.data.transaction_id).then(res => {
                setBtnCls('btn btn-outline w-full mt-5')
                setBtnTxt('ETH obtinguts')
                console.log(res)
                setModal(balanceModal3(r.data.transaction_id, closeModal))
            }).catch((err) => {
                throw {response:{data:{message:'Error processant la transacció'}}}
            })

        }).catch((e) => {
            setModal(balanceModalErr(e.response.data.message))
            setBtnCls('btn btn-outline w-full mt-5')
            setBtnTxt('Obtenir ETH')
        })
        e.target.disabled = false
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    function copy (){
        navigator.clipboard.writeText(account).then(() => {
            setDataTip('Copiat!')
            delay(1000).then(() => setDataTip(('Copiar Adreça')))
        }).catch((e) => {
            console.log('error copiant adreça')
        })
    }

    return(<Fragment>
            <div className="flex flex-row justify-center">
                <div className="badge badge-lg badge-outline  p-4">
                    {account.substring(0,5)}...{account.substring(account.length-4)}
                    <div className="tooltip tooltip-bottom" data-tip={dataTip}>
                        <svg onClick={copy} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" ml-2 w-4 h-4 cursor-pointer hover:animate-pulse">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                        </svg>
                    </div>
                    <div className="tooltip tooltip-bottom tooltip-success" data-tip="Connectat">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="green" className="w-4 h-4 cursor-pointer">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="text-mono text-bold mx-auto" style={{fontSize:'4rem'}}>{balance.toString().substring(0,6)}</div>
            <div className="text-mono text-bold mx-auto" style={{fontSize:'1rem'}}>ETH</div>
            <button className={btnCls} onClick={handleFaucet}>{btnTxt}</button>
        </Fragment>
    )
}

export const balanceModal1 = <Fragment>
    <ul className="steps w-full mx-0 px-0">
        <li className="step step-info text-xs">Petició</li>
        <li className="step text-xs">Processament</li>
        <li className="step text-xs">Completada</li>
    </ul>
    <img src={nft} className="mx-auto w-1/2 py-8"/>
    <p className="text-center font-bold p-2">Generant transacció</p>
    <p className="text-center mt-3 text-sm">Estem enviant al teu compte 0.4 sepolia ETH</p>
</Fragment>

export function balanceModal2(hash){
    return(
        <Fragment>
            <ul className="steps w-full mx-0 px-0">
                <li data-content="✓" className="step step-info text-xs">Petició</li>
                <li className="step step-info text-xs">Processament</li>
                <li className="step text-xs">Completada</li>
            </ul>
            <img src={nft} className="mx-auto w-1/2 py-8"/>
            <p className="text-center font-bold p-2">Processant transacció</p>
            <p className="text-center mt-3 text-sm">Pots comprovar els detalls de la transacció <a href={"https://sepolia.etherscan.io/tx/"+hash} target="_blank" className="link link-primary">aquí</a></p>
            <div onClick={()=>{document.getElementById('modal').className = "modal"}} className="btn btn-sm btn-info mt-5 flex justify-center">Seguir comprant</div>

        </Fragment>
    )
}
export function balanceModal3(hash, close){
    return(
        <Fragment>
            <ul className="steps w-full mx-0 px-0">
                <li data-content="✓" className="step step-info text-xs">Petició</li>
                <li data-content="✓" className="step step-info text-xs">Processament</li>
                <li data-content="✓" className="step step-info text-xs">Completada</li>
            </ul>
            <img src={nft} className="mx-auto w-1/2 py-8"/>
            <p className="text-center font-bold p-2">Transacció completada!</p>
            <p className="text-center mt-3 text-sm">Pots comprovar els detalls de la transacció <a href={"https://sepolia.etherscan.io/tx/"+hash} target="_blank" className="link link-primary">aquí</a></p>
            <div onClick={close} className="btn btn-sm btn-info mt-5 flex justify-center">Tancar</div>
        </Fragment>
    )
}
export function balanceModalErr(err){
    return(
        <Fragment>
            <ul className="steps w-full mx-0 px-0">
                <li data-content="!" className="step step-error text-xs">Petició</li>
                <li data-content="✕" className="step step-error text-xs">Processament</li>
                <li data-content="✕" className="step step-error text-xs">Completada</li>
            </ul>
            <div className="flex flex-row justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(248, 114, 114)" className="w-40 h-40 my-8">
                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                </svg>

            </div>
            <p className="text-center font-bold p-2">Error durant la transacció</p>
            <p className="text-center mt-1 text-sm">{err}</p>
            <div onClick={()=>{document.getElementById('modal').className = "modal"}} className="btn btn-sm btn-error mt-5 flex justify-center">Tancar</div>
        </Fragment>
    )

}
