import {config} from "./Constants";
import axios from "axios";

const url = config.url.API_URL

export async function get_user_data(){

    try{
        let name, address, joined, nitems, nft
        const r = await axios.get(url + '/api/nftuser', {auth: {password:window.localStorage.getItem('token')}})

        console.log(r.data)
        const json = r.data

        if (json['username'] === json['address']){
            name = (json['username'].substring(0,5)+'...'+json['username'].substring(json['username'].length-4))
        } else {
            name = (json['username'])
        }

        address = json['address'] // = account
        joined = json['date']

        try{
            nitems = Object.keys(json['NFT']).length
            nft = json['NFT']
        } catch {
            nitems = 0
        }

        return {'name': name, 'address': address, 'joined': joined, 'nitems': nitems, 'nft': nft}

    } catch (e) {
        throw e.toString()
    }
}
export async function getLikes(){
    try{
        const likes = await axios.get(url+"api/user/likes", {auth:{password:window.localStorage.getItem('token')}})
        return likes.data.data
    } catch (e){
        throw "error getting likes: " + e
    }
}
export async function update_nft_state(id, new_state){
    try{
        return await axios.post(url + '/api/nft/onsale',
                            {'id':id, 'onsale':new_state},
                            {auth:{password:window.localStorage.getItem('token')}})
    } catch (e){
        throw 'error updating state onsale='+new_state+' to NFT with id '+id+'\nreason: '+ e.toString()
    }
}
