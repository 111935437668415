import {isSepolia} from "../../logic/MetamaskLogic";
import {useMetaMask} from "metamask-react";
import {def_chain} from "../../logic/Constants";

export default function WalletBtn({status, id}){
    if (status === 'connected'){
        if (!isSepolia(id)) {return (<WalletBtnAux text="Canvia a Sepolia" click={true} />)}
        else {return(<WalletBtnAux text="Connected" classname="btn-accent animate-none cursor-default"/>)}
    }
    else if (status === 'unavailable' || status === 'notConnected'){return <WalletBtnAux text="Disconnected" classname="btn-error animate-none cursor-default"/>}
    else if (status === 'initializing' || status === 'connecting'){return <WalletBtnAux classname="loading"/>}

}

function WalletBtnAux({text, classname, click}){
    const {switchChain} = useMetaMask()

    if (click){
        return (<button onClick={() => switchChain(def_chain)} className={"btn btn-sm rounded-full my-auto " + classname}>{text}</button>)
    }
    return (<button className={"btn btn-sm rounded-full my-auto " + classname}>{text}</button>)
}
