import {getChainName, isSepolia} from "../../logic/MetamaskLogic"
import {def_chain} from "../../logic/Constants";
import dofi from "../../img/sepolia_dolphin.png"
import {useEffect} from "react";

export default function NetCard({id, switchChain, setModal}){

    useEffect(() => {
        if (!isSepolia(id)) {
            switchChain(def_chain)
        }
    },[id])

    const check = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10">
        <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
    </svg>

    const warning = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgba(255,0,0,0.7)" className="w-10 h-10">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
    </svg>

    return(
        <div className="flex flex-col mt-5">
            <div className="card w-full bg-base-300">
                <div className="flex flex-col p-4">
                    <div className="flex flex-row">
                        <div className="flex flex-col shrink-0">
                            <div className="text-xs">Connectat a</div>
                            <h2 className="text-lg capitalize">{getChainName(id)}</h2>
                        </div>
                        <div className="flex flex-row justify-end w-full">
                            {isSepolia(id)
                                ? check
                                : warning}
                        </div>
                    </div>
                    {!isSepolia(id) && <button onClick={() => switchChain(def_chain)} className="btn dark:btn-outline mt-5 w-full">Canvia a Sepolia</button>}
                </div>
            </div>
        </div>
    )
}

export function netModal(chainSwitch){
    return(
        <div className="flex flex-col w-full justify-center">
            <img className="mx-auto" src={dofi} width={60}/>
            <div className="text-2xl font-bold text-center mt-5">Canvia a la xarxa Sepolia</div>
            <div className="text-md mx-auto mt-2 text-center ">CriptoArt utiltza la xarxa de proves Sepolia per a operar de manera gratuïta.</div>
            <div className="text-sm mx-auto mt-4 ">Accepta el canvi al teu  moneder</div>
            <svg className="animate-spin my-5 h-10 w-10 text-info mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div className="text-xs mx-auto">No apareix res? <a href="/" className="link">Actualitza</a></div>
        </div>
    )
}
