import { Fragment } from "react";
import { Text } from "@react-three/drei";

export default function Frame({position, material, material2, geometry1, geometry2, texture, title, creator, rotation, perpendicular}){
    return(
        <Fragment>
                  <group position={position} rotation={rotation !== undefined ? rotation : [0.00000000000001, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={geometry1}
          material={material}
        />
        {material2 !== undefined &&
            <mesh
            castShadow
            receiveShadow
            geometry={geometry2}
            material={material2}
            />
        }
        {texture !== undefined &&
            <mesh onClick={() => {console.log('clicked')}}
            castShadow
            receiveShadow
            geometry={geometry2}
            >
                <meshStandardMaterial map={texture} />
            </mesh>
        }
      </group>
      <mesh position={[position[0], position[1] -1.35, position[2] -1 ]}>
         <Text scale={0.05} position={[0.01,0,0.2]} maxWidth={20} rotation={[0,Math.PI/2,0]} color="black" anchorX="left" anchorY="middle" textAlign="left"
        >{title}</Text>
        <Text scale={0.05} position={[0.01,-0.06,0.2]} maxWidth={20} rotation={[0,Math.PI/2,0]} color="black" anchorX="left" anchorY="middle" textAlign="left"
        >{creator}</Text>
      </mesh>
        </Fragment>
    )
}


/*
<group position={[-8, 2.6, -10.1]} rotation={[0.00000000000001, 0, Math.PI]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922002"].geometry}
          material={materials.Painting_Back}
        />
        <mesh onClick={() => {console.log('clicked')}}
          castShadow
          receiveShadow
          geometry={nodes["pexels-steve-johnson-6377922002_1"].geometry}
          material={materials["Painting.007"]}
        >
            <meshStandardMaterial map={colorMap2} />
        </mesh>
      </group>

      <mesh position={[-8, 1.25, -11.1 ]}>
         <Text scale={0.05} position={[0.01,0,0.2]} maxWidth={20} rotation={[0,Math.PI/2,0]} color="black" anchorX="left" anchorY="middle" textAlign="left"
        >YETI NFT</Text>
        <Text scale={0.05} position={[0.01,-0.06,0.2]} maxWidth={20} rotation={[0,Math.PI/2,0]} color="black" anchorX="left" anchorY="middle" textAlign="left"
        >By 0x1234...4567</Text>
      </mesh>
*/