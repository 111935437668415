import {useMetaMask} from "metamask-react";
import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {ABI_NFT_MARKETPLACE, ABI_NFT_MINTING, ADDRESS_NFT_MARKETPLACE} from "../../smartcontracts/ABI";
import axios from "axios";
import {checkURL, contractFactory, isContract} from "../../logic/SmartContractLogic";
import WalletInfo from "./WalletInfo";
import {dataModalApprove, dataModalList, dataModalListOk} from "./ListModal";
import {useParams} from "react-router-dom";
function VerifyMessage({msg}) {
    return(
        <div className="flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="my-auto mt-6 w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
            <div className="text-sm my-auto text-red-500 mt-5 ml-2 truncate w-80">{msg}</div>
        </div>
    )
}

function NFTOwnerRight(){
    return(
        <div className="flex flex-row">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="green" className=" my-auto mt-6 w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>
            <div className="text-sm my-auto mt-5 ml-2">ets el propietari de l'NFT</div>
        </div>
    )
}

export default function ListForm({setModal}){

    const { status, connect, account, chainId, ethereum, switchChain } = useMetaMask();
    const [addrErr, setAddrErr] = useState('border-gray-600')
    const [idErr, setIdErr] = useState('border-gray-600')
    const [priceErr, setPriceErr] = useState('border-gray-600')
    const [msgErr, setMsgErr] = useState('')
    const [verifyMSG, setVerifyMSG] = useState('')
    const [name, setName] = useState('')
    const [desc, setDesc] = useState('')
    const { contractAddress, id } = useParams();
    const delay = ms => new Promise(res => setTimeout(res, ms));


    useEffect(() => {
        document.getElementById('list').disabled = true
    }, [])

    useEffect(() => {
        if (contractAddress !== undefined && id !== undefined){
            try{
                document.getElementById('NFTCA').value = contractAddress
                document.getElementById('CID').value = id
                verify(ethereum)
            } catch (e) {}
        }
    },[status])

    async function verify(ethereum){

        document.getElementById('verify').disabled = true


        // default value
        setAddrErr('border-gray-600')
        setIdErr('border-gray-600')
        setVerifyMSG('')
        setName('')
        setDesc('')

        document.getElementById('price').disabled = true
        document.getElementById('list').disabled = true
        document.getElementById('foto').style.backgroundImage = ''

        if(status !== 'connected'){
            setVerifyMSG(<VerifyMessage msg="Primer connecta el teu moneder."/> )
            document.getElementById('verify').disabled = false
            throw 'Error durant la verificació: Moneder no connectat'
        }

        // getting user input
        const addr = document.getElementById('NFTCA').value
        const id = parseInt(document.getElementById('CID').value)


        const provider = new ethers.providers.Web3Provider(ethereum)

        // NFT contract addr not valid
        if (!await isContract(addr,provider)){
            setAddrErr('input-error')
            setVerifyMSG(<VerifyMessage msg="L'adreça del contracte de l'NFT no és vàlida"/>)
            document.getElementById('verify').disabled = false
            throw "addr: " + addr.toString() + " no és una adreça vàlida"
        }

        // ID not valid
        if(isNaN(id)){
            setIdErr('input-error')
            setVerifyMSG(<VerifyMessage msg="Token ID no vàlid"/>)
            document.getElementById('verify').disabled = false
            throw "Token id: " +id+ 'no és vàlid'
        }

        try{
            const contract = contractFactory(addr, ABI_NFT_MINTING, provider)

            //check if you own this NFT
            const owner = await contract.ownerOf(id)

            if (owner.toLowerCase() !== account.toLowerCase()){
                setAddrErr('input-error')
                setIdErr('input-error')
                setVerifyMSG(<VerifyMessage msg="No ets el propietari de l'NFT"/>)
                document.getElementById('verify').disabled = false
                return -1
            }

            let url = await contract.tokenURI(id)
            url = checkURL(url)

            //afegir un try-catch???
            const f = await axios.get(url)
            document.getElementById('foto').style.backgroundImage = `url(${checkURL(f.data.image)})`
            setName(f.data.name)
            setDesc(f.data.description)

        } catch (e) {
            setAddrErr('input-error')
            setIdErr('input-error')

            if (e.reason !== undefined){
                setVerifyMSG(<VerifyMessage msg={e.reason}/>)
            } else {
                setVerifyMSG(<VerifyMessage msg="Error Verificant l'NFT"/>)
            }

            document.getElementById('verify').disabled = false
            throw 'Error verifying '+ e.toString()
        }

        setVerifyMSG(<NFTOwnerRight/>)
        document.getElementById('price').disabled = false
        document.getElementById('list').disabled = false
        document.getElementById('verify').disabled = false

    }

    async function listNFT(){

        document.getElementById('list').disabled = true
        setPriceErr('border-gray-600')
        setMsgErr('')

        const addr = document.getElementById('NFTCA').value
        const id = parseInt(document.getElementById('CID').value)
        const price = parseFloat(document.getElementById('price').value)

        if (price <= 0 || isNaN(price)){
            setPriceErr('border-red-500')
            setMsgErr('El preu ha de ser superior a 0')
            document.getElementById('list').disabled = false
            throw "Price Must be higher than 0"
        }

        //open modal
        document.getElementById('modal').className = "modal modal-open"

        const overrides = {
            value: ethers.utils.parseEther('0.025'),
            gasLimit: 10000000
        }

        const provider = new ethers.providers.Web3Provider(ethereum)
        const signer = provider.getSigner(ethers.utils.getAddress(account))

        const contract_market = contractFactory(ADDRESS_NFT_MARKETPLACE, ABI_NFT_MARKETPLACE, signer)
        const contract_nft_minting = contractFactory(addr, ABI_NFT_MINTING, signer)

        try{
            const approvedValue = await contract_nft_minting.getApproved(id)
            if(approvedValue.toLowerCase() !== ADDRESS_NFT_MARKETPLACE.toLowerCase()){
                //add data to modal
                setModal(dataModalApprove)
                //approve
                await contract_nft_minting.approve(contract_market.address, id)
            }
            //add data to modal
            setModal(dataModalList)
            // list
            const tx = await contract_market.createMarketItem(contract_nft_minting.address, id, ethers.utils.parseUnits(price.toString(), "ether"), overrides)
            setModal(dataModalListOk(tx.hash))
            await tx.wait();

            //close modal
            document.getElementById('modal').className = "modal"
            window.location = "/nft/" + contract_nft_minting.address + "/" + id

        } catch (e) {
            //close modal
            document.getElementById('modal').className = "modal"
            setPriceErr('border-red-500')
            document.getElementById('list').disabled = false
            setMsgErr("Error fent el listing de l'NFT" + (e.reason === undefined ? '.' : ': ' + e.reason))

            throw "Error listing NFT: "+ e
        }

    }

    return(
        <div className="flex flex-row flex-wrap w-full">
            <div className="flex flex-col w-full">
                <div className="font-mono font-bold text-4xl text-center sm:text-start">
                    Afegeix el teu NFT (List)
                </div>
                <div className="font-mono text-base text-center sm:text-start px-4 sm:px-0">
                    <br/>
                    Ven el teu NFT a la nostra tenda! (comissió de 0.025 ETH).
                    <br/>
                    l'NFT ha d'estar creat a la xarxa de proves Sepolia i ser compatible amb l'estàndard ERC-721.
                </div>

                <div className="pt-5 form-control w-full">
                    <div className="flex flex-row flex-wrap w-full gap-10 justify-between">
                        {/*columna esquerra (form)*/}
                        <div className="flex flex-col flex-wrap gap-5">
                            {/*wallet info*/}
                            <WalletInfo account={account} chainId={chainId} status={status}/>
                            {/*Row form*/}
                            <div className="flex flex-row flex-wrap w-full gap-4">
                                {/*Input contract addr*/}
                                <div className="flex flex-col w-full md:w-80">
                                    <label className="label">
                                        <span className="label-text">Adreça del contracte de l'NFT</span>
                                    </label>
                                    <input id="NFTCA" type="text" placeholder="0x..." className={"input input-bordered w-full " + addrErr} />
                                </div>
                                {/*Input Token ID*/}
                                <div className="flex flex-col w-full md:w-20">
                                    <label className="label">
                                        <span className="label-text">Token ID</span>
                                    </label>
                                    <input id="CID" type="number" min="0" className={"input input-bordered w-full " + idErr} />
                                </div>
                            </div>
                            {/*Verify button*/}
                            <div className="flex flex-row flex-wrap w-full gap-4">
                                <button id="verify" onClick={() =>verify(ethereum)} className="btn btn-accent btn-sm rounded-full w-25 mt-4">Verificar</button>
                                {verifyMSG}
                            </div>
                            {/*price form*/}
                            <div>
                                <div className="flex flex-col w-full">
                                    <div className="flex flex-col w-full">
                                        <label className="label">
                                            <span className="label-text">Preu de l'NFT</span>
                                        </label>
                                        <div className={"border rounded-xl " + priceErr}>
                                            <div className="flex flex-row h-full w-full gap-3 pr-4">
                                                <div className="flex p-3 rounded-lg">ETH</div>
                                                <input disabled id="price" type="number" min="0.0" step="0.5" placeholder="0,0" className="my-auto bg-transparent w-full focus:outline-none"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*List Button*/}
                                <div className="text-sm text-red-500 mt-3">{msgErr}</div>
                                <input type="submit" onClick={listNFT} id="list" className="w-full btn btn-accent rounded-lg mt-5" value="List NFT"/>
                            </div>
                        </div>
                        {/*Columna dreta (preview)*/}
                        <div className="flex flex-col flex-wrap">
                            <label className="label">
                                <span className="label-text">Previsualització</span>
                            </label>
                            <div id="foto" className="bg-cover bg-center py-40 w-80 border rounded-2xl border-gray-600" ></div>
                            <div className="pt-5 font-mono w-80 overflow-hidden">Nom: {name}</div>
                            <div className="pt-2 font-mono w-80 overflow-hidden">Descripció: {desc}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
