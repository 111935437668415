export default function ElementMenu({parentClassName, title, text, icon, altIcon, data}){

        return(
            <div className={"flex flex-col bg-base-300 w-full " + parentClassName}>
                <div className="flex flex-row p-3 border-b border-neutral-600/40 justify-between w-full">
                    <div className="flex flex-row w-full gap-2">
                        {icon}
                        <div className="font-semibold font-sans">{title}</div>
                    </div>
                    {Object.keys(data).length > 0
                        ? altIcon
                        : <div role="status" className=" my-auto animate-pulse">
                            <div className="rounded-full p-3 bg-neutral-600/50"></div>
                        </div>
                    }
                </div>
                { text !== undefined
                    ? <div className="font-sans p-3">{text}</div>
                    : <div role="status" className="max-w-sm animate-pulse p-3">
                        <div className="h-2.5 bg-gray-200 rounded-full bg-neutral-600/50 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full bg-neutral-600/50 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full bg-neutral-600/50 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full bg-neutral-600/50 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full bg-neutral-600/50 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full bg-neutral-600/50 max-w-[360px]"></div>
                        <span className="sr-only">Carregant...</span>
                    </div>
                }
            </div>
        )
}
