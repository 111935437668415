import {Link} from "react-router-dom";
import {Fragment} from 'react';

export default function NFTCard({title, autor, preu, img, onsale, changeType, id, list, unlist, marketId, addr, type}){
    function unlistNFT(){
        unlist(marketId)
        onsale = false
    }

    function Btns(){
        return(<Fragment>
            {type !== 4 && onsale /*TODO: vigilar pq quan afegim NFTs creats per nosaltres pero que no son nostres ara no podrem fer cap d'aquestes opcions*/
                ? <button onClick={() => unlistNFT()} className="btn btn-sm rounded-full mt-2">Retirar de la venda</button>
                : <Link to={"/listnft/"+addr+"/"+id} className="btn btn-sm rounded-full mt-2">Posar a la venda</Link>}
        </Fragment>)
    }

    return(
        <div className="card w-80 bg-base-content/10 shadow-md mx-auto">
            <figure><Link to={"/nft/"+addr+"/"+id}><img className="h-80" src={img} alt="Shoes" /></Link></figure>
            <div className="card-body">
                <Link to={"/nft/"+addr+"/"+id} className="card-title">
                    {title}
                </Link>
                <p className="truncate">{autor}</p>
                {preu !== null && <p>{preu} ETH</p>}
                {type !== '4' && <Btns/>}
            </div>
        </div>
    )
}
