import eth from "../../img/eth.svg";
import {ShrinkAccount} from "../metamask/Metamask";
import WalletBtn from "./WalletBtn";

export default function WalletInfo({account, chainId, status}){
    return(
        <div className="flex flex-col w-full">
            <label className="label">
                <span className="label-text">El teu moneder</span>
            </label>
            <div className="border rounded-lg border-gray-600">
                <div className="flex flex-row py-2 px-4 justify-between gap-6">
                    <div className="flex flex-row gap-6">
                        <img src={eth} width="40"/>
                        <div className="my-auto">
                            <ShrinkAccount classname="my-auto" account={account} text="DESCONEGUT"/>
                        </div>
                    </div>
                    <WalletBtn status={status} id={chainId}/>
                </div>
            </div>
        </div>
    )
}
