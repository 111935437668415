import {Fragment, useEffect, useState} from 'react';
import Featured from "./Featured";
import Hammer from "rc-hammerjs/lib/Hammer";
export default function Slides({data}){
    const [currentIndex, setCurrentIndex] = useState(0);

    /*
    useEffect(() => {
        setTimeout(nextSlide, 6000)
    },[currentIndex])

     */

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? data.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        const isLastSlide = currentIndex === data.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);

    };

    const goToSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };

    const handleSwipeRight = () => {
        prevSlide()
    }

    const handleSwipeLeft = () => {
        nextSlide()
    }


    if (data.length > 0) {
        return(
            <div className="group">
                <Hammer onSwipeRight={handleSwipeRight} onSwipeLeft={handleSwipeLeft}>
                    <Featured  data={data[currentIndex]}/>
                </Hammer>
                {/* Left Arrow */}
                <div className='hidden group-hover:block absolute top-[46%] -translate-x-0 translate-y-[-50%] left-2 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <div onClick={prevSlide}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
                {/* Right Arrow */}
                <div className='hidden group-hover:block absolute top-[46%] -translate-x-0 translate-y-[-50%] right-2 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
                    <div onClick={nextSlide}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-7 h-7">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clipRule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>

        )
    }

    return(
        <div className="flex justify-between flex-wrap bg-base-300 rounded-none md:rounded-3xl mt-5 mb-6 mx-auto max-w-[1400px]
                        flex-col-reverse px-[4%] py-[5%] gap-6
                        md:flex-row  md:gap-[4%] md:p-[3%] ">

            {/*Columna esquerra: Data*/}
            <div className="flex flex-col w-full md:w-[48%] md:min-h-[55vh]">
                <div className="flex flex-row gap-8">
                    <div className="animate-pulse bg-info/80 text-black rounded-full py-1 px-3 font-bold font-mono">Carregant...</div>
                    <div className="font-bold font-mono my-auto bg-base-content/10 py-4 px-8 rounded-full animate-pulse"></div>
                </div>
                <div className="flex flex-col mt-20">
                    <div className="py-5 mb-2 font-black text-5xl lg:text-7xl px-40 rounded-lg animate-pulse bg-base-content/10 font-mono my-auto text-clip overflow-hidden"></div>
                    <div className=" py-5 mb-20 font-black text-5xl lg:text-7xl px-20 rounded-lg animate-pulse bg-base-content/10 font-mono my-auto text-clip overflow-hidden"></div>
                </div>
                {/*creador row*/}
                <div className="flex flex-row gap-4">
                    <div className="flex flex-col my-auto">
                        <div className="mask mask-circle p-4 bg-base-content/10 animate-pulse"></div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xs">creat per</div>
                        <div className="text-md font-bold mt-1 py-1 px-5 animate-pulse bg-base-content/10"></div>
                    </div>
                </div>
                {/*price + button row*/}
                <div className="flex flex-row pt-10 gap-10">
                    <div className="flex flex-row gap-2 items-end my-auto">
                        <div className="text-4xl font-extrabold duration-500 py-2 px-3 bg-base-content/10 animate-pulse"></div>
                        <div className="text-sm">ETH</div>
                    </div>
                    <div className="btn btn-sm btn-info font-bold my-auto loading px-20"></div>

                </div>
            </div>
            {/*Columna dreta: Imatge*/}
            <div className="flex flex-col rounded-3xl w-full md:w-[48%] bg-center bg-cover h-[55vh] duration-500 bg-base-content/10 animate-pulse"> </div>
        </div>
    );
}
