import {ethers} from "ethers";

export function contractFactory(address, ABI, provider){
    try {
        return new ethers.Contract(address, ABI, provider);
    } catch (e) {
        console.log("error creating contract")
        throw "Error creating contract: " + e
    }
}

export async function isContract(address, provider){
    try {
        const code = await provider.getCode(address);
        if (code !== '0x') return true; //a contract
    } catch (error) {
        return false //not a contract
    }
}

export function checkURL(url){
    //si comença ipfs:// afegir https
    const prefix = 'https://ipfs.io/ipfs/'
    if (url.includes('ipfs://')){
        return url.replace('ipfs://', prefix)
    }

    if (url.includes('http://') || url.includes('https://')){
        return url
    }

    return prefix + url
}
