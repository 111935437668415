// Constants.js
const prod = {
    url: {
        API_URL: 'https://158.101.213.244/'
    }
};
const dev = {
    url: {
        API_URL: 'http://192.168.3.12/'
        //API_URL: 'http://127.0.0.1/'
        //API_URL: 'https://35.180.119.133/'
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export const def_chain = "0xaa36a7"
