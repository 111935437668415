import {def_chain} from "./Constants";
import {ethers} from "ethers";
import axios from "axios";
import {config} from "./Constants";

const url = config.url.API_URL
const transactionParameters = {
    nonce: '0x00', // ignored by MetaMask
    to: '0x0000000000000000000000000000000000000000', // Required except during contract publications.
    from: '0x0000000000000000000000000000000000000000', // must match user's active address.
    value: '0x00', // Only required to send ether to the recipient from the initiating external account.
    chainId: def_chain, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
};

export async function sendTransaction(value, from, to, ethereum){
    // set transactions parameters
    transactionParameters.value = Number(ethers.utils.parseUnits(value.toString(), "ether")).toString(16)
    transactionParameters.from = from
    transactionParameters.to = to
    // execute Metamask transaction
    const txHash = await ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
    });
    console.log("txHash: ",txHash)
    return txHash
}

export async function validatePurchase(id, txhash){

    const msg = await axios.post(url + 'api/nftuser',
                        {'id': id, 'txhash':txhash},
                        {auth: {password: window.localStorage.getItem('token')}})

    console.log('backend purchase message: ', msg)
    return msg
}

export async function getNFTs(){
    let nfts;

    if (window.localStorage.getItem('nfts') !== null){
        nfts = JSON.parse(window.localStorage.getItem('nfts'))

    } else {
        nfts = await fetch_nfts()
        nfts = nfts.data
    }
    return nfts
}

export async function fetch_nfts(){
    const time = Date.now()
    const nfts = await axios.get(url + 'api/nfts')
    window.localStorage.setItem('nfts',JSON.stringify(nfts.data))
    window.localStorage.setItem('nfts_timestamp', time.toString())
    return nfts

}
