export default function Footer(){
    return(<footer className="footer p-10 bg-neutral text-neutral-content">
        <div>
            <div className="flex flex-row flex-nowrap gap-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-9 h-9 my-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
                </svg>
                <div className="font-bold py-5" style={{fontSize:'2.5rem'}}>CriptoArt</div>
            </div>
            <p>Albert Martínez<br/>Treball de final de Grau 2023</p>
        </div>
        <div>
            <span className="footer-title"></span>
            <div className="grid grid-flow-col gap-4">
            </div>
        </div>
    </footer>)
}
