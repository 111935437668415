import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MetaMaskProvider } from "metamask-react";
import {createBrowserRouter,RouterProvider, ScrollRestoration } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Profile from "./pages/Profile";
import UploadNFT from "./pages/UploadNFT";
import MintNFT from "./pages/MintNFT";
import ListNFT from "./pages/ListNFT";
import NFTInfo from "./pages/NFTInfo";
import Gallery3D from "./galeria/Gallery3D";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
        /*errorElement:*/
    },
    {
        path: "/profile",
        element: <Profile/>
    },
    {
        path: "/upload",
        element: <UploadNFT/>
    },
    {
        path: "/mintnft",
        element: <MintNFT/>
    },
    {
        path: "/listnft/:contractAddress?/:id?",
        element: <ListNFT/>
    },
    {
        path: "/nft/:contractAddress/:id",
        element: <NFTInfo/>
    },
    {
        path: "/galeria3D",
        element: <Gallery3D/>
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <MetaMaskProvider>
          <RouterProvider router={router} />
      </MetaMaskProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
