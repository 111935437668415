import {Fragment, useEffect, useState} from 'react';
import Gallery from "./Gallery";
import {get_user_data, getLikes} from "../../logic/ProfileLogic";
import Blockies from 'react-blockies';

export default function ProfileData(){

    const [name, setName] = useState()
    const [address, setAdress] = useState()
    const [join, setJoined] = useState()
    const [nitems, setNItems] = useState()
    const [active, setActive] = useState('1')
    const [nft, setNft] = useState([])
    const [likes, setLikes] = useState()

    useEffect(() => {
        handleData()
        getLikes().then((r)=>{
            console.log(r)
            setLikes(r)
        })
    },[])

    function handleData(){
        get_user_data().then((r) => {
            if (r['name'] != null) {setName(r['name'])}
            if(r['address'] != null) {setAdress(r['address'])}
            if(r['joined'] != null) {setJoined(r['joined'])}
            if(r['nitems'] != null) {setNItems(r['nitems'])}
            if(r['nft'] != null) {setNft(r['nft'])}
        })
    }

    function handleTabClick(e){
        console.log("Selected tab id:",e.target.id)

        if (document.getElementById(active).classList.contains('tab-active')){
            document.getElementById(active).classList.remove('tab-active')
        }
        setActive(e.target.id)
        e.target.classList.add('tab-active')
    }

    return(<Fragment>
        <div className="flex flex-row flex-wrap gap-5 justify-center md:justify-start pt-5 gap-8">
            {/*columna 1 Img*/}
            <div className="flex flex-col flex-wrap w-full sm:w-auto">
                {address !== undefined
                    ? <Blockies seed={address.toLowerCase()} size={9} scale={21} className="mx-auto sm:mx-0 mask mask-squircle"/>
                    : <div className="flex mx-auto sm:mx-0 mask mask-squircle py-20 px-20 bg-base-content/10 animate-pulse"></div> }
            </div>
            {/*Columna 2*/}
            <div className="flex flex-wrap flex-col">
                {name !== undefined
                    ? <p className="mx-auto sm:mx-0 text-xl font-bold">{name}</p>
                    : <p className="mx-auto sm:mx-0 py-2 w-1/4 bg-base-content/10 animate-pulse rounded-full mb-3"></p>}
                {/*address row*/}
                <div className="flex flex-row mx-auto sm:mx-0">
                    { address !== undefined
                        ? <p className="text-sm sm:text-xs">{address}</p>
                        : <p className="bg-base-content/10 py-2 px-10 animate-pulse rounded-lg"></p> }
                </div>
                {/*items on sale row*/}
                <div className="flex flex-row mt-7 gap-3 mx-auto sm:mx-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 my-auto">
                        <path d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
                    </svg>
                    {nitems !== undefined
                        ?<p>Tens {nitems} NFTs</p>
                        :<p className="bg-base-content/10 py-2 px-20 animate-pulse rounded-lg"></p>}
                </div>
                {/*Joined date row*/}
                <div className="flex flex-row mt-2 gap-3 mx-auto sm:mx-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 my-auto">
                        <path d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z" clipRule="evenodd" />
                    </svg>
                    {join !== undefined
                        ? <p>Vas unir-te el {join}</p>
                        : <p className="bg-base-content/10 animate-pulse rounded-full py-2 px-20"></p>}
                </div>
                {/*Edit button
                            <button className="btn btn-sm btn-info mt-3 rounded-2xl">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2 my-auto">
                                    <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                    <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                </svg>

                                Edit profile</button>
                                */}
                {/*Tab section*/}
                <div className="flex flex-row overflow-hidden flex-nowrap tabs mt-6">
                    <a id="1" onClick={handleTabClick} className={`tab tab-bordered lg:px-8 tab-active`}>Els meus NFT</a>
                    <a id="2" onClick={handleTabClick} className={`tab tab-bordered lg:px-8`}>NFTs en venta</a>
                    <a id="3" onClick={handleTabClick} className={`tab tab-bordered lg:px-8`}>Les meves creacions</a>
                    {likes !== undefined
                        ? <a id="4" onClick={handleTabClick} className={`tab tab-bordered lg:px-8`}>Preferits</a>
                        : <div className="tab tab-bordered lg:px-8"><div className="px-10 py-2 bg-base-content/10 animate-pulse"></div></div>}
                </div>

            </div>
        </div>
        <Gallery data={nft} likes={likes} type={active} handleData={handleData}/>
    </Fragment>)
}
